//hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//services
import { api } from "../../../services/api/api";
//utils
import { validateFields } from "../../../utils/form.validator";
import { responseError } from "../../../utils/responsesFunctions/error.response";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Button } from "../../../components/buttons/button.default";

export function InflatorFinancialCreate() {

    useEffect(() => {
        setLoading(true)

        async function getPaymentCondition() {
            const paymentCondition = await api.get(`/api/v1/lm/paymentCondition`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setPaymentConditionData(paymentCondition?.data?.data.filter(item => item.textDays = 'Dias'))
        }

        getPaymentCondition()
        setLoading(false)
    }, [])

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'paymentConditionId', value: paymentConditionId, required: true, type: 'string' },
            { name: 'percentage', value: percentage, required: true, type: 'string' },
            { name: 'startDate', value: startDate, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/lm/inflatorFinancial', {
                paymentCondition_id: paymentConditionId.id,
                percentage: percentage,
                startDate: startDate
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/inflatorFinancial')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [paymentConditionData, setPaymentConditionData] = useState([])

    const [paymentConditionId, setPaymentConditionId] = useState('')
    const [startDate, setStartDate] = useState('')
    const [percentage, setPercentage] = useState('')

    return (
        <Container>
            <TitlePage>
                <div>
                    <Title text={'Cadastro de Inflator Financeiro'}></Title>
                </div>
            </TitlePage>
            {
                loading ?
                    <DefaultLoader />
                    :
                    <Body>
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput 
                                    text={'Condição de pagamento'} 
                                    required={true}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir condição com base no que o cliente parametrizar.'}
                                >
                                    <InputAutoComplete
                                        width={80}
                                        data={paymentConditionData}
                                        optionList={["id", "days", "textDays"]}
                                        selectedLabel={["days"]}
                                        value={paymentConditionId ? paymentConditionId?.days + ' - Dias' : ''}
                                        id='paymentConditionId'
                                        onChange={(e) => setPaymentConditionId(e)}
                                    />
                                </LabelInput>
                                <LabelInput text={'Data Inicial'} required={true}>
                                    <Input
                                        type={'date'}
                                        width={80}
                                        value={startDate ? startDate : ''}
                                        id='startDate'
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </LabelInput>
                                <LabelInput text={'Porcentagem %'} required={true}>
                                    <Input
                                        type={'number'}
                                        width={'110px'}
                                        value={percentage ? percentage : ''}
                                        id='percentage'
                                        onChange={(e) => setPercentage(e.target.value)}
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                <Button
                                    onClick={() => navigate('/inflatorFinancial')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <Button
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Cadastrar
                                </Button>
                            </div>
                        </div>
                    </Body>
            }
        </Container>
    )
}