//hooks
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from '../../../utils/validators/justNumber'
//components
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import CurrencyInput from "../../../components/input/inputCurrencyPtBr";
import { Button } from "../../../components/buttons/button.default";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { api } from '../../../services/api/api'

export function ShoppingCreate() {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [productsData, setProductsData] = useState([])
    const [customersOfCustomer, setCustomersOfCustomer] = useState([])
    const [dateEntry, setDateEntry] = useState('')
    const [supplierAndCustomer_id, setSupplierAndCustomer_id] = useState('')
    const [products_id, setProducts_id] = useState('')
    const [quantity, setQuantity] = useState('')
    const [convertUnitMeasure, setConvertUnitMeasure] = useState('')
    const [unitPrice, setUnitPrice] = useState('')
    const [icms, setIcms] = useState('')
    const [freight, setFreight] = useState('')
    const [icmsFreight, setIcmsFreight] = useState('')

    useEffect(() => {
        async function getData() {
            setLoading(true)
            try {
                const getProductsAPI = await api.get(`/api/v1/lm/products?active=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setProductsData(getProductsAPI?.data?.data.filter(item => !['PA', 'PI'].includes(item?.typeProduct)))
    
                const getCustomersOfCustomer = await api.get(`/api/v1/general/supplierAndCustomer?isSupplier=true`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setCustomersOfCustomer(getCustomersOfCustomer?.data?.data)
                setLoading(false)
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
                }
            }
        }

        getData()
    }, [])

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'dateEntry', value: dateEntry, required: true, type: 'string' },
            { name: 'supplierAndCustomer_id', value: supplierAndCustomer_id, required: true, type: 'string' },
            { name: 'products_id', value: products_id, required: true, type: 'string' },
            { name: 'quantity', value: quantity, required: true, type: 'string' },
            { name: 'unitPrice', value: unitPrice, required: true, type: 'string' },
            { name: 'icms', value: icms, required: true, type: 'string' },
            { name: 'icmsFreight', value: icmsFreight, required: freight && true, type: 'string' },
            { name: 'convertUnitMeasure', value: convertUnitMeasure, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/lm/shopping', {
                dateEntry: dateEntry,
                supplierAndCustomer_id: supplierAndCustomer_id?.id,
                products_id: products_id?.id,
                quantity: quantity,
                convertUnitMeasure: convertUnitMeasure,
                unitPrice: unitPrice,
                icms: icms,
                freight: freight ? freight : undefined,
                icmsFreight: icmsFreight ? icmsFreight : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/shopping')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Compras'} />
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader />
                    :
                    <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                        <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                            <LabelInput text={'Data de entrada'} required={true}>
                                <Input
                                    type={'date'}
                                    width={80}
                                    value={dateEntry ? dateEntry : ''}
                                    id='dateEntry'
                                    onChange={(e) => setDateEntry(e.target.value)}
                                />
                            </LabelInput>
                            <LabelInput text={'Fornecedor'} required={true}>
                                <InputAutoComplete
                                    width={80}
                                    data={customersOfCustomer}
                                    optionList={["id", "businessName"]}
                                    selectedLabel={["businessName"]}
                                    value={supplierAndCustomer_id ? supplierAndCustomer_id?.businessName : ''}
                                    id='supplierAndCustomer_id'
                                    onChange={(e) => setSupplierAndCustomer_id(e)}
                                />
                            </LabelInput>
                            <LabelInput text={'Produto'} required={true}>
                                <InputAutoComplete
                                    width={80}
                                    data={productsData}
                                    optionList={["code", "description", "typeProduct"]}
                                    selectedLabel={["description"]}
                                    value={products_id ? products_id?.description : ''}
                                    id='products_id'
                                    onChange={(e) => setProducts_id(e)}
                                />
                            </LabelInput>
                            <LabelInput text={'Quantidade'} required={true}>
                                <Input
                                    type={'number'}
                                    width={80}
                                    value={quantity ? quantity : ''}
                                    id='quantity'
                                    onChange={(e) => setQuantity(e.target.value)}
                                />
                            </LabelInput>
                            <LabelInput text={'Conversão U.M %'} required={true}>
                                <Input
                                    type={'text'}
                                    width={80}
                                    value={convertUnitMeasure ? convertUnitMeasure : ''}
                                    id='convertUnitMeasure'
                                    onChange={(e) => setConvertUnitMeasure(justNumber(e.target.value))}
                                />
                            </LabelInput>
                            <LabelInput text={'Preço Unitário'} required={true}>
                                <CurrencyInput id='unitPrice' placeHolder={unitPrice ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(unitPrice) : 'R$ 0,00'}
                                    onChange={(e) => {
                                        setUnitPrice(parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')))
                                    }}></CurrencyInput>
                            </LabelInput>
                            <LabelInput text={'ICMS %'} required={true}>
                                <Input
                                    type={'number'}
                                    width={80}
                                    value={icms ? icms : ''}
                                    id='icms'
                                    onChange={(e) => setIcms(e.target.value)}
                                />
                            </LabelInput>
                            <LabelInput text={'Frete'} >
                                <CurrencyInput id='freight' placeHolder='R$ 0,00'
                                    onChange={(e) => {
                                        setFreight(parseFloat(e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')))
                                    }}></CurrencyInput>
                            </LabelInput>
                            {
                                <LabelInput text={'ICMS Frete %'} required={freight ? true : false} >
                                    <Input
                                        type={'number'}
                                        width={80}
                                        value={icmsFreight ? icmsFreight : ''}
                                        id='icmsFreight'
                                        onChange={(e) => setIcmsFreight(e.target.value)}
                                        disabled={freight ? false : true}
                                    />
                                </LabelInput>
                            }
                        </form>
                        <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                            <Button
                                onClick={() => navigate('/shopping')}
                                shadow={true}
                                approval={false}
                            >Cancelar
                            </Button>
                            <Button
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Cadastrar
                            </Button>
                        </div>
                    </div>
                }
            </Body>
        </Container>
    )
}