export const filterParams = [
    {
        id: "businessName",
        description: "Empresa",
        colspan: 1,
        editable: false,
        filter: 'businessName'
    },
    {
        id: "saleDate",
        description: "Data",
        colspan: 1,
        editable: false,
        filter: 'saleDate'
    },
    {
        id: "saleOrder",
        description: "Pedido",
        colspan: 1,
        editable: false,
        filter: 'saleOrder'
    },
    {
        id: "nf",
        description: "Nota Fiscal",
        colspan: 1,
        editable: false,
        filter: 'nf'
    },
    {
        id: "client_description",
        description: "Cliente",
        colspan: 1,
        editable: false,
        filter: 'client_description'
    },
    {
        id: "externalId",
        description: "Código Externo Cliente",
        colspan: 1,
        editable: true
    },
    {
        id: "addressState",
        description: "Estado",
        colspan: 1,
        editable: false,
        filter: 'addressState'
    },
    {
        id: "description",
        description: "Produto",
        colspan: 1,
        editable: false,
        filter: 'product'
    }, 
    {
        id: "code",
        description: "Código Produto",
        colspan: 1,
        editable: true
    },
    {
        id: "product_externalId",
        description: "Código Externo Produto",
        colspan: 1,
        editable: true
    },  
    {
        id: "typeProduct",
        description: "Tipo",
        colspan: 1,
        editable: true
    },  
    {
        id: "seller_description",
        description: "Representante",
        colspan: 1,
        editable: false,
        filter: 'seller_description'
    },     
    {
        id: "unitMeasure",
        description: "UM",
        colspan: 1,
        editable: true
    },  
    {
        id: "productsGroup",
        description: "Grupo do Produto",
        colspan: 1,
        editable: false,
        filter: 'productsGroup'
    },
    {
        id: "quantity",
        description: "Quantidade",
        colspan: 1,
        editable: true
    },  
    {
        id: "unitPrice",
        description: "Preço de Venda(Unit.)",
        colspan: 1,
        editable: true
    },  
    {
        id: "discount",
        description: "Desconto",
        colspan: 1,
        editable: true
    },  
    {
        id: "totalSale",
        description: "Total c/ desconto",
        colspan: 1,
        editable: true
    }, 
    {
        id: "cost",
        description: "Custo Unit. Materiais",
        colspan: 1,
        editable: true
    },
    {
        id: "factorMeasurable",
        description: "Fat. Total Mensuravel",
        colspan: 1,
        editable: true
    },
    {
        id: "cpv",
        description: "CPV",
        colspan: 1,
        editable: true
    },
    {
        id: "cpvFat",
        description: "CPV x Fat.",
        colspan: 1,
        editable: true
    },
    {
        id: "commission",
        description: "Comissão",
        colspan: 2,
        editable: true
    },
    {
        id: "freight",
        description: "Frete",
        colspan: 2,
        editable: true  
    },
    {
        id: "days",
        description: "Cond. Pgto",
        colspan: 1,
        editable: true
    },
    {
        id: "totalChange",
        description: "Despesa Financeira",
        colspan: 2,
        editable: true
    },
    {
        id: "baseTax",
        description: "Base Imposto",
        colspan: 1,
        editable: true
    },
    {
        id: "icms",
        description: "ICMS",
        colspan: 2,
        editable: true
    },
    {
        id: "pis",
        description: "PIS",
        colspan: 2,
        editable: true
    },
    {
        id: "cofins",
        description: "Cofins",
        colspan: 2,
        editable: true
    },
    {
        id: "simplePercentage",
        description: "Simples Nacional",
        colspan: 2,
        editable: true
    },
    {
        id: "ircs",
        description: "IR/CS",
        colspan: 2,
        editable: true
    },
    {
        id: "iva",
        description: "IVA",
        colspan: 2,
        editable: true
    },
    {
        id: "priceLiquid",
        description: "Preço líquido",
        colspan: 1,
        editable: true
    },
    {
        id: "marginTotal",
        description: "Margem de Contribuiçao",
        colspan: 3,
        editable: true
    },

]   