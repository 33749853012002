import { useEffect, useState } from "react";
//components
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { validateFields } from "../../../utils/form.validator";
import { useAuth } from "../../../contexts/useAuth";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
//context
import { useThemeContext } from "../../../contexts/themeContext";
import { useNavigate, useParams } from 'react-router-dom'
//api
import { api } from "../../../services/api/api";
//utils
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { Toggle } from "../../../components/toogle/Toogle";
//typp
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaLock, FaLockOpen } from "react-icons/fa";

export function DeterminationPeriodEdit() {
    const navigate = useNavigate()
    const { id } = useParams()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [periodData, setPeriodData] = useState()
    const [loading, setLoading] = useState(false)
    const [statusPeriod, setStatusPeriod] = useState()
    const [periodName, setPeriodName] = useState('')

    const monthData = [
        { id: 1, name: 'Janeiro' },
        { id: 2, name: 'Fevereiro' },
        { id: 3, name: 'Março' },
        { id: 4, name: 'Abril' },
        { id: 5, name: 'Maio' },
        { id: 6, name: 'Junho' },
        { id: 7, name: 'Julho' },
        { id: 8, name: 'Agosto' },
        { id: 9, name: 'Setembro' },
        { id: 10, name: 'Outubro' },
        { id: 11, name: 'Novembro' },
        { id: 12, name: 'Dezembro' },
    ]

    const yearsData = Array.from({ length: 100 }, (value, index) => {
        return {
            id: index,
            value: 2000 + (index + 1)
        }
    })

    useEffect(() => {
        async function getPeriods() {
            try {

                const getPeriodsApi = await api.get(`/api/v1/general/periods/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                setPeriodData(getPeriodsApi.data.data[0])
                setStatusPeriod(getPeriodsApi.data.data[0]?.isOpen)
                setPeriodName(`${monthData?.filter(e => e?.id == getPeriodsApi.data.data[0]?.month)[0]?.name}/${getPeriodsApi.data.data[0]?.year}`)

            } catch (error) {
                setShowModificationModal(true)
                if (responseError(error).length > 0) {
                    setShowNotificationModalText(responseError(error))
                } else {
                    setShowNotificationModalText('Erro inesperado')
                }
                setLoading(false)
                setShowNotificationModalSuccess(false)
            }
        }
        getPeriods()
    }, [])

    async function alterStatusPeriod() {
        const status = !statusPeriod
        try {

            await api.put(`api/v1/general/periods/alterStatus/${id}`, {
                status: status

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText(`${status === false ? 'Período fechado com sucesso' : 'Período aberto com sucesso'}`)
            setShowNotificationModalSuccess(true)
            setStatusPeriod(status)

        } catch (error) {
            setShowModificationModal(true)
            if (responseError(error).length > 0) {
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalText('Erro inesperado')
            }
            setLoading(false)
            setShowNotificationModalSuccess(false)
        }
    }

    async function handleSubmit() {

        const requiredFields = [
            { name: 'month', value: periodData?.month, required: true, type: 'string' },
            { name: 'year', value: periodData?.year, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)

        try {

            await api.put(`api/v1/general/periods/${id}`, {
                month: Number(periodData.month),
                year: Number(periodData.year)

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso')
            setShowNotificationModalSuccess(true)
            navigate('/determinationPeriod')

        } catch (error) {

            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/general/periods/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/determinationPeriod')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exclusão de período'}>
                <div className='w-full py-10 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir este periodo ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Período - ${periodName}`} />
                    </div>
                </TitlePage>
                <Body>
                    <>
                        {
                            loading ?
                                <DefaultLoader />
                                :
                                <>
                                    <div className='flex flex-col gap-4 items-center lg:items-start justify-center lg:justify-start w-full'>
                                        <form className="flex flex-col lg:flex lg:flex-row items-center gap-4">
                                            <LabelInput text={'Mês *'}>
                                                <InputAutoComplete
                                                    data={monthData}
                                                    selectedLabel={['name']}
                                                    optionList={['name']}
                                                    value={monthData.filter(item => item?.id === periodData?.month)[0]?.name}
                                                    id='month'
                                                    preSelectedValue={monthData.filter(item => item?.id === periodData?.month)[0]?.name}
                                                    width={80}
                                                    charLimit={4}
                                                    onChange={(e) => setPeriodData(prev => ({ ...prev, month: e?.id }))}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Ano *'}>
                                                <InputAutoComplete
                                                    type={'text'}
                                                    data={yearsData}
                                                    optionList={['value']}
                                                    selectedLabel={['value']}
                                                    id='year'
                                                    width={80}
                                                    preSelectedValue={periodData?.year}
                                                    value={periodData?.value}
                                                    onChange={(e) => setPeriodData(prev => ({ ...prev, year: e?.value }))} />
                                            </LabelInput>
                                            <div className="pt-6 flex gap-2 items-center">
                                                {
                                                    (userData[0]?.permissions?.indexOf('periods:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                    <div id='clearFilter' className='lg:flex lg:flex-row justify-start items-center gap-2 cursor-pointer'>
                                                        <Toggle
                                                            status={statusPeriod ? true : false}
                                                            onClick={(e) => alterStatusPeriod()}
                                                            greenAndRed={true}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    statusPeriod ? <div className="flex justify-center items-center gap-2 text-green-800 dark:text-green-500">Aberto <FaLockOpen /></div>
                                                        :
                                                        <div className="flex justify-center items-center gap-2 text-red-800">Fechado <FaLock /></div>
                                                }
                                            </div>
                                        </form>
                                        <div className='pt-5 lg:pt-0 flex flex-col lg:flex lg:flex-row gap-3 lg:w-full justify-center lg:justify-between'>
                                            <Button approval={false} onClick={() => navigate('/determinationPeriod')}>Cancelar</Button>
                                            <div className='flex flex-col lg:flex lg:flex-row gap-3'>
                                                {
                                                    (userData[0]?.permissions?.indexOf('periods:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                    <Button approval={false} onClick={() => setShowUniversalModal(true)}>Excluir</Button>
                                                }
                                                {
                                                    (userData[0]?.permissions?.indexOf('periods:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                                    <Button onClick={() => handleSubmit()}>Editar</Button>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </>
                        }
                    </>
                </Body>
            </Container>
        </>
    )
} 