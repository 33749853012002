//hooks
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Tippy from "@tippyjs/react";
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Toggle } from "../../../components/toogle/Toogle";
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext';
//services
import { api } from "../../../services/api/api";
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from '../../../utils/validators/justNumber'

export function PaymentConditionEdit() {

    const { id } = useParams()
    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [activeCondition, setActiveCondition] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()

    const [paymentConditionData, setPaymentConditionData] = useState('')

    useEffect(() => {
        setLoading(true)

        async function getPaymentConditionsAPI() {
            const getPaymentCondition = await api.get(`/api/v1/lm/paymentCondition/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setPaymentConditionData(getPaymentCondition?.data?.data)
            setActiveCondition(getPaymentCondition?.data?.data?.active)
        }

        getPaymentConditionsAPI()
        setLoading(false)
    }, [])

    async function conditionStatus() {

        const status = !activeCondition
        setActiveCondition(status)

        try {

            await api.put(`/api/v1/lm/paymentCondition/alterStatus/${id}`, {
                status: status
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowNotificationModalText(status === false ? `Condição de pagamento desativada com sucesso.` : `Condição de pagamento ativada com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function handleSubmit() {

        setLoading(true)
        try {

            await api.put(`api/v1/lm/paymentCondition/${id}`, {
                description: paymentConditionData?.description ? paymentConditionData?.description : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/paymentCondition')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Condição de Pagamento`} />
                    </div>
                </TitlePage>                
                <Body>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput 
                                    text={'Dias'} 
                                    required={true}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir quantidade de dias para ser aplicado ao inflator financeiro (parametrização panorama).'}
                                >
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={paymentConditionData?.days}
                                        id='days'
                                        disabled={true}
                                        onChange={(e) => setPaymentConditionData((prev) => ({ ...prev, days: justNumber(e.target.value) }))}
                                        charLimit={4}
                                    />
                                </LabelInput>
                                <LabelInput text={'Descrição'}>
                                    <Input
                                        type={'string'}
                                        width={80}
                                        value={paymentConditionData?.description ? paymentConditionData?.description : ''}
                                        id='description'
                                        onChange={(e) => setPaymentConditionData((prev) => ({ ...prev, description: e.target.value }))}
                                        charLimit={255}
                                    />
                                </LabelInput>
                            </form>
                            <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                <Button
                                    onClick={() => navigate('/paymentCondition')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <div className='flex gap-3'>
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_paymentCondition:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <>
                                            <div className='mt-[8px]'>
                                                <Tippy content={<span>{activeCondition ? 'Desativar' : 'Ativar'}</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <Toggle status={activeCondition ? true : false} onClick={(e) => conditionStatus()} />
                                                    </div>
                                                </Tippy>
                                            </div>
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                }
                </Body>
            </Container>
        </>
    )
}