//hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
//services
import { api } from "../../../services/api/api";
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'

export function InflatorFinancialEdit() {

    useEffect(() => {
        setLoading(true)

        async function getInflatorFinancialAPI() {
            const getInflatorFinancialData = await api.get(`/api/v1/lm/inflatorFinancial/${id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setInflatorFinancialData(getInflatorFinancialData?.data?.data)
        }

        async function getPaymentCondition() {
            const paymentCondition = await api.get(`/api/v1/lm/paymentCondition`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setPaymentConditionData(paymentCondition?.data?.data.filter(item => item.textDays = 'Dias'))
        }

        getInflatorFinancialAPI()
        getPaymentCondition()
        setLoading(false)
    }, [])

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/lm/inflatorFinancial/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/inflatorFinancial')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'paymentConditionId', value: inflatorFinancialData?.paymentConditionId, required: true, type: 'string' },
            { name: 'percentage', value: inflatorFinancialData?.percentage, required: true, type: 'string' },
            { name: 'startDate', value: inflatorFinancialData?.startDate, required: true, type: 'string' },
        ]

        if (inflatorFinancialData?.paymentCondition_id == undefined) {
            requiredFields.push({ name: 'paymentConditionId', value: inflatorFinancialData?.paymentCondition_id, required: true, type: 'object' })
        } else {
            requiredFields.push({ name: 'paymentConditionId', value: inflatorFinancialData?.paymentCondition_id, required: true, type: 'number' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/lm/inflatorFinancial', {
                paymentCondition_id: inflatorFinancialData?.paymentCondition_id,
                percentage: inflatorFinancialData?.percentage,
                startDate: inflatorFinancialData?.startDate
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/inflatorFinancial')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [inflatorFinancialData, setInflatorFinancialData] = useState('')
    const [paymentConditionData, setPaymentConditionData] = useState([])

    return (
        <>
            <ScreenModal title={'Exclusão de Inflator Financeiro'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div>
                        <Title text={'Edição de Inflator Financeiro'}></Title>
                    </div>
                </TitlePage>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <Body>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput 
                                        text={'Condição de pagamento'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir condição com base no que o cliente parametrizar.'}
                                    >
                                        <InputAutoComplete
                                            width={80}
                                            data={paymentConditionData}
                                            optionList={["id", "days", "textDays"]}
                                            selectedLabel={["days"]}
                                            value={inflatorFinancialData?.paymentCondition_id ? paymentConditionData.filter(item => item.id == inflatorFinancialData?.paymentCondition_id)[0]?.days == undefined ? '' : paymentConditionData.filter(item => item.id == inflatorFinancialData?.paymentCondition_id)[0]?.days + ' Dias' : ''}
                                            preSelectedValue={inflatorFinancialData?.paymentCondition_id ? paymentConditionData.filter(item => item.id == inflatorFinancialData?.paymentCondition_id)[0]?.days == undefined ? '' : paymentConditionData.filter(item => item.id == inflatorFinancialData?.paymentCondition_id)[0]?.days + ' Dias' : ''}
                                            id='paymentConditionId'
                                            onChange={(e) => setInflatorFinancialData(prev => ({ ...prev, paymentCondition_id: e.id }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data Inicial'} required={true}>
                                        <Input
                                            type={'date'}
                                            width={80}
                                            value={inflatorFinancialData?.startDate ? inflatorFinancialData?.startDate : ''}
                                            id='startDate'
                                            onChange={(e) => setInflatorFinancialData(prev => ({ ...prev, startDate: e.target.value }))}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Porcentagem %'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={'110px'}
                                            value={inflatorFinancialData?.percentage ? inflatorFinancialData?.percentage : ''}
                                            id='percentage'
                                            onChange={(e) => setInflatorFinancialData(prev => ({ ...prev, percentage: e.target.value }))}
                                        />
                                    </LabelInput>
                                </form>
                                <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <Button
                                        onClick={() => navigate('/inflatorFinancial')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    <div className='flex gap-2'>
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_inflatorFinancial:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                approval={false}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_inflatorFinancial:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Body>
                }
            </Container>
        </>
    )
}