//hooks
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from '../../../utils/validators/justNumber'
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { api } from '../../../services/api/api'

export function PaymentConditionCreate() {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [days, setDays] = useState('')
    const [description, setDescription] = useState('')

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'days', value: days, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {

            await api.post('api/v1/lm/paymentCondition', {
                days: days,
                description: description ?? undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/paymentCondition')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={'Cadastro de Condições de Pagamento'} />
                    </div>
                </TitlePage>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <Body>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput 
                                        text={'Dias'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir quantidade de dias para ser aplicado ao inflator financeiro (parametrização panorama).'}
                                    >
                                        <Input
                                            type={'string'}
                                            width={80}
                                            value={days ? days : ''}
                                            id='days'
                                            onChange={(e) => setDays(justNumber(e.target.value))}
                                            charLimit={4}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Descrição'}>
                                        <Input
                                            type={'string'}
                                            width={80}
                                            value={description ? description : ''}
                                            id='description'
                                            onChange={(e) => setDescription(e.target.value)}
                                            charLimit={255}
                                        />
                                    </LabelInput>
                                </form>
                                <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                    <Button
                                        onClick={() => navigate('/paymentCondition')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    <Button
                                        shadow={true}
                                        onClick={() => handleSubmit()}
                                    >Cadastrar
                                    </Button>
                                </div>
                            </div>
                        </Body>
                }
            </Container>
        </>
    )
}