//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Tippy from "@tippyjs/react";
import { FaArrowDown, FaEdit, FaFilter } from "react-icons/fa";
import { FiSlash } from 'react-icons/fi';
//contexts
import { useThemeContext } from '../../../contexts/themeContext';
import { useAuth } from '../../../contexts/useAuth';
//utils
import { justNumber } from '../../../utils/validators/justNumber';
//services
import { api } from '../../../services/api/api';
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
import { TablePaginationDefault } from '../../../components/table/tablePagination.default';
import { Body } from '../../../components/container/Body';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { Input } from '../../../components/input/input';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import moment from 'moment';
import { responseError } from '../../../utils/responsesFunctions/error.response';
import { LoadingPulse } from '../../../components/loaders/loadingPulse';

export function DataSheet() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({  })

    const [quantity, setQuantity] = useState(0)
    const [productsData, setProductsData] = useState([])
    const [datasheetApi, setDatasheetData] = useState([])
    const [lastRunning, setLastRunning] = useState('')
    const [modalLast, setModalLast] = useState(false)
    const [animateThisModal, setAnimateThisModal] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            modalLast ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (modalLast) {
            animateModal(true)
        } else {
            animateModal(false)
        }
    }, [modalLast])

    async function getData(){
        setLoading(true)
        try {
            const datasheetApi = await api.get(`/api/v1/lm/datasheet`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setDatasheetData(datasheetApi?.data?.data)
            
            const productsDataApi = await api.get('/api/v1/lm/products?active=true', {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setProductsData(productsDataApi?.data?.data?.filter(item => ['PA', 'PI'].includes(item?.typeProduct)))
            
            const getLastRunningAPI = await api.get(`/api/v1/lm/runningValorize`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            if (getLastRunningAPI?.data?.data[0]?.runned_at){
                setLastRunning(moment(getLastRunningAPI?.data?.data[0]?.runned_at)?.format('DD/MM/YYYY HH:mm:ss'))
            }
            setLoading(false)
        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleEdit(datasheet) {
        navigate(`/datasheetEdit/${datasheet?.id}`)
    }

    async function handleRunningValorize(){
        try {
            setLoading(true)
            await api.post(`/api/v1/lm/runningValorize`,{}, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Valorização de fichas técnicas realizada com sucesso')
            setShowNotificationModalSuccess(true)
            setModalLast(false)
            await getData()
        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function exportDatasheet(){
        try {

        const urlContructor = ['']

        Object.keys(filterConstructor).map(key => {
            if(![null, undefined, '', NaN].includes(filterConstructor[key])){
                urlContructor.push(`${key}=${filterConstructor[key]}`)
            }
        })
        
        const dataExports = await api.get('/api/v1/lm/datasheet/exports' + '?' + urlContructor.join('&'), {
            headers: {
                authorization: `Bearer ${userData[0].token}`
            },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(dataExports?.data)
        const downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', url)
        downloadElement.setAttribute('download', `Fichas_${new Date().toISOString().split('T')[0]}`)
        downloadElement.click()

        } catch(error){
            if (responseError(error).length > 0) {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            } else {
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText(responseError(error))
                setLoading(false)
            }
        }
    }

    return (
        <>
            {
                modalLast &&
                <div className='flex flex-col'>
                    <div className={`${modalLast ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[800]`}>
                        <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} w-[350px] transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                            <div className='flex flex-row justify-between items-center p-2'>
                                <h1 className='text-base text-titleBlackTextLight dark:text-titleGrayTextLight'>Valorizar fichas técnicas</h1>
                                <button onClick={() => setModalLast(false)} className='bg-gray-300 w-6 h-6 rounded-full'>x</button>
                            </div>
                            <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                <div className='flex flex-col items-center justify-center w-96 p-4'>
                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base mb-6'>Deseja realmente valorizar?</p>
                                    <div className='flex flex-row w-full justify-center gap-4 items-center'>
                                        <Button approval={true} onClick={() => handleRunningValorize()} shadow={true}>Sim</Button>
                                        <Button approval={false} onClick={() => setModalLast(false)} shadow={true}>Não</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${modalLast ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[1000vh] sm:h-full z-50`}></div>
                </div>
            }
            <ScreenModal title={'Filtros'}>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <div className='flex flex-col pt-2 pb-5 px-12 gap-2 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Produto *</a>
                                <InputAutoComplete
                                    data={productsData}
                                    selectedLabel={'description'}
                                    optionList={["code", "description", "typeProduct"]}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, products_id: e.id })}
                                    preSelectedValue={datasheetApi.filter(item => item.products_id == productsData.id)[0]?.observation}
                                    id='products_id'
                                    width={80}
                                    height={96}
                                />
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                <InputAutoComplete
                                    data={[{ id: false, description: 'Inativo' }, { id: true, description: 'Ativo' }]}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                                    preSelectedValue={[{ id: false, description: 'Inativo' }, { id: true, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                                    id='active'
                                    width={80}
                                    height={60}
                                />
                            </label>
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container validPeriod_lm={true}>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between  pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Ficha Técnica'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 items-center'>
                                {quantity == 1 ?
                                    <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Ficha técnica</h3>
                                    :
                                    <h3 className='text-xs 2xl:text-sm font-light dark:text-titleGrayTextDark'>{quantity} Fichas Técn.</h3>
                                }
                                <div className='hidden md:flex flex-row justify-start items-center gap-1 cursor-pointer ml-3' onClick={() => exportDatasheet()}>
                                    <Tippy
                                        content={<span>Exportar lista em excel</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='bottom'
                                        delay={80}
                                    >
                                        <p className='flex flex-row items-center justify-center gap-1 text-xs 2xl:text-sm dark:text-gray-300'>Exportar <FaArrowDown className='dark:text-titleGrayTextDark text-xs 2xl:text-sm' /></p>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                        {
                            (userData[0]?.permissions?.indexOf('lm_datasheet:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                            <>
                                <div className='hidden sm:flex flex-row gap-5 items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/datasheetCreate')}>
                                    <Button shadow={true} width={40} onClick={() => navigate('/datasheetCreate')}>+ Nova ficha técnica</Button>
                                </div>
                                <div className='sm:hidden flex flex-col items-center justify-end' onClick={() => navigate('/datasheetCreate')}>
                                    <Button shadow={true} onClick={() => navigate('/datasheetCreate')}>Nova</Button>
                                </div>
                            </>
                        }
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <LoadingPulse/>
                        :
                        <>
                            <div className='w-full sm:mt-0 mt-2 items-center flex gap-4 justify-between'>
                                <div className='flex flex-row items-center gap-4'>
                                    <Button onClick={() => { setShowUniversalModal(true) }}>
                                        <FaFilter className='text-white' />
                                        <p className='text-white'> Filtrar </p>
                                    </Button>
                                    {
                                        filter && JSON.stringify(filter) != JSON.stringify({  }) ? 
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoading(true)
                                                    setFilter({  })
                                                    setFilterConstructor({})
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoading(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                        : <></>
                                    }
                                </div>
                                <div className='flex flex-col gap-1 items-center'>
                                    {
                                        lastRunning &&
                                        <p className='text-xs'>{lastRunning}</p>
                                    }
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_runningValorize:create') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button onClick={() => setModalLast(true)}>
                                            Valorizar
                                        </Button>
                                    }
                                </div>
                            </div>
                            <TablePaginationDefault
                                onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                quantity={(e) => setQuantity(e)}
                                apiUrl={'/api/v1/lm/datasheet'}
                                filters={filter}
                                mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                colSpan={9}
                                collumns={[
                                    {
                                        name: 'code',
                                        type: 'number',
                                        description: 'Código'
                                    },
                                    {
                                        name: 'description',
                                        type: 'string',
                                        description: 'Produto'
                                    },
                                    {
                                        name: 'version_id',
                                        type: 'string',
                                        description: 'Versão'
                                    },
                                    {
                                        name: 'startDate',
                                        type: 'date',
                                        description: 'Data criação'
                                    },
                                ]}
                            />
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}