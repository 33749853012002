import axios from "axios";

export const api = axios.create({
    baseURL: process.env?.NODE_ENV == 'development' || process.env?.NODE_ENV == 'test'
        ? 'http://localhost:3333'
        : 'https://app.pnr.adm.br',
    withCredentials: true
})

// export const api = axios.create({
//     baseURL: 'http://app.pnr.adm.br',
//     withCredentials: true
// })