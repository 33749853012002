// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { maskCep } from '../../../utils/validators/cepMask'
import { searchCep } from '../../../utils/validators/searchCep'
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { changeThemeColor } from '../../theme/changeColors'
//services
import { api } from '../../../services/api/api'
import { FaInfoCircle } from 'react-icons/fa'

export function CompaniesCreate({hasCustomer, notHasCustomers}) {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    
    // Fields
    const [companiesList, setCompaniesList] = useState([])
    const [businessName, setBusinessName] = useState('')
    const [fantasyName, setFantasyName] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [emailMaster, setEmailMaster] = useState('')
    const [phone, setPhone ] = useState('')
    const [cep, setCep] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [selectedColor, setSelectedColor] = useState('')
    const [selectedCustomer, setSelectedCustomer] = useState('')
    
    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getCustomers(){
            try {
                
                const getCustomersAPI = await api.get('/api/v1/customers', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                
                setCompaniesList(getCustomersAPI?.data?.data)
                
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getStates()
        getCustomers()

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/distritos`)
            const getCityData = await getCity.json()
            
            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleCep(){

        const cepData = await searchCep(cep)

        setAddressState(cepData?.uf)
        setAddressCity(cepData?.localidade)
        setAddressDistrict(cepData?.bairro)
        setAddressRoad(cepData?.logradouro)

    }

    async function handleSubmit(){
        
        setLoading(true)

        const requiredFields = [
            { name: 'businessName', value: businessName, required: true, type: 'string' },
            { name: 'customer', value: selectedCustomer, required: true, type: 'string' },
            { name: 'addressState', value: addressState, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!isValidEmail) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Email inválido')
            return setLoading(false)
        }

        try {
            
            await api.post('api/v1/companies', {
                businessName: businessName,
                fantasyName: fantasyName ? fantasyName : undefined,
                cnpj: cnpj ? cnpj?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                email: emailMaster ? emailMaster : undefined,
                colorIdentifier: selectedColor ? selectedColor : undefined,
                phone: phone ? phone : undefined,
                cep: cep ? cep.replace('-', '') : undefined,
                addressState: addressState ? addressState : undefined,
                addressCity: addressCity ? addressCity : undefined,
                addressDistrict: addressDistrict ? addressDistrict : undefined,
                addressRoad: addressRoad ? addressRoad : undefined,
                addressNumber: addressNumber ? addressNumber : undefined,
                addressComplement: addressComplement ? addressComplement : undefined,
                customers_id: selectedCustomer?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            if (hasCustomer){
                return notHasCustomers(false)
            }
            navigate('/companies')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Empresa'} />
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader/>
                    :
                    <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                        <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                            <LabelInput text={'Razão social *'}>
                                <Input 
                                    type={'text'} 
                                    width={80}
                                    autoFocus={true} 
                                    value={businessName} 
                                    id='businessName' 
                                    onChange={(e) => setBusinessName(e.target.value)} 
                                    charLimit={200} 
                                />
                            </LabelInput>
                            <LabelInput text={'Nome fantasia'}>
                                <Input 
                                    type={'text'} 
                                    width={80}
                                    value={fantasyName} 
                                    id='name' 
                                    onChange={(e) => setFantasyName(e.target.value)} 
                                    charLimit={200} 
                                />
                            </LabelInput>
                            <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                <Input type={'text'} width={80} value={emailMaster} id='email' onChange={(e) => {
                                    const isValid = validateEmail(e.target.value)
                                    if (isValid === true) {
                                        setIsValidEmail(true)
                                    } else {
                                        setIsValidEmail(false)
                                    }
                                    setEmailMaster(e.target.value)
                                }} charLimit={255} />
                            </LabelInput>
                            <LabelInput text={'CNPJ'}>
                                <Input type={'text'} 
                                    value={cnpjMask(cnpj)} 
                                    width={80}
                                    id='cnpj' onChange={(e) => setCnpj(cnpjMask(e.target.value))} 
                                    charLimit={18} 
                                />
                            </LabelInput>
                            <LabelInput 
                                text={'Cliente *'}
                                showIconInfo={true}
                                messageIconInfo={'Definir qual cliente a empresa pertence.'}
                            >
                                <InputAutoComplete 
                                    type={'text'} 
                                    width={80}
                                    value={selectedCustomer?.businessName} 
                                    id='customer' onChange={(e) => setSelectedCustomer(e)}
                                    data={companiesList}
                                    optionList={['id', 'businessName']}
                                    selectedLabel={'businessName'}
                                />
                            </LabelInput>
                            <LabelInput text={'CEP'}>
                                <Input 
                                    type={'text'} 
                                    width={80}
                                    value={maskCep(cep)} 
                                    id='cep' onChange={(e) => setCep(e.target.value)}
                                    charLimit={9} 
                                    onBlur={(e) => {
                                        handleCep()
                                    }}
                                />
                            </LabelInput>
                            <LabelInput text={'Estado'} required={true}>
                                <InputAutoComplete
                                    preSelectedValue={addressState ? addressStateList.filter(e => { return e?.sigla == addressState })[0]?.nome : ''}
                                    value={addressState?.nome}
                                    selectedLabel={'nome'} id='addressState'
                                    data={addressStateList} optionList={['nome']}
                                    onChange={e => {
                                        {
                                            setAddressState(e?.sigla)
                                            e?.nome !== undefined && getCityApi(e)
                                        }
                                    }}>
                                </InputAutoComplete>
                            </LabelInput>
                            {
                                !addressState ?
                                    <LabelInput text={'Cidade'}>
                                        <Input width={80} type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                    </LabelInput>
                                    :
                                    <LabelInput text={'Cidade'}>
                                        <InputAutoComplete
                                            preSelectedValue={addressCity ? addressCity : ''}
                                            selectedLabel={'nome'} id='addressCity'
                                            width={80}
                                            data={addressCityList} optionList={['nome']}
                                            onChange={e => {
                                                setAddressCity(e?.nome)
                                            }}>
                                        </InputAutoComplete>
                                    </LabelInput>
                            }
                            <LabelInput text={'Bairro'}>
                                <Input width={80} type={'text'} value={addressDistrict} onChange={(e) => setAddressDistrict(e.target.value)} charLimit={255} />
                            </LabelInput>
                            <LabelInput text={'Logradouro'}>
                                <Input width={80} type={'text'} value={addressRoad} onChange={(e) => setAddressRoad(e.target.value)} charLimit={255} />
                            </LabelInput>
                            <LabelInput text={'Número'}>
                                <Input width={80} type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                            </LabelInput>
                            <LabelInput text={'Complemento'}>
                                <Input width={80} type={'text'} value={addressComplement} id='addressComplement' onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                            </LabelInput>
                            <LabelInput text={'Telefone com DDD'}>
                                <Input charLimit={12} type={'text'} width={80} onChange={(e) => setPhone(e.target.value)} value={phone}></Input>
                            </LabelInput>
                            <LabelInput 
                                text={'Cor do cliente'} 
                                icon={
                                    <Tippy
                                        key={'iconInfo'}
                                        content={'Definir a cor que aparecerá no rodapé do sistema.'}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div className='flex items-center ml-3'>
                                            <FaInfoCircle className='text-sm cursor-help text-gray-700 flex items-center justify-center'/>                            
                                        </div>
                                    </Tippy>
                                }
                            >
                                <input 
                                    value={selectedColor} 
                                    onChange={(e) => { changeThemeColor('selectedColor', e.target.value); setSelectedColor(e.target.value) }} 
                                    className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-16 h-16 sm:w-12 sm:h-8 outline-none dark:bg-secondaryDefaultDark' 
                                    type='color'
                                />
                            </LabelInput>
                        </form>
                        <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                            {
                                !hasCustomer &&
                                <Button
                                    onClick={() => navigate('/companies')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                            }
                            <Button
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >+ Nova empresa
                            </Button>
                        </div>
                    </div>
                }            
            </Body>
        </Container>
    )
}