//hooks
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from '../../../components/label/label.input';
import { Input } from '../../../components/input/input';
import { InputAutoComplete } from '../../../components/input/input.autocomplete';
import CurrencyInput from '../../../components/input/inputCurrencyPtBr';
import { Button } from '../../../components/buttons/button.default';
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { Body } from '../../../components/container/Body';
import { DefaultLoader } from '../../../components/loaders/defaultLoader';
import { Toggle } from "../../../components/toogle/Toogle";
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { unitMeasures } from "../../../utils/unitMeasure/unitMeasureList";
import { typeProducts } from "../../../utils/typeProduct/typeProduct";
import { validateFields } from '../../../utils/form.validator'
import { justNumber } from '../../../utils/validators/justNumber'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext';
//services
import { api } from '../../../services/api/api'
// tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { TableDefault } from '../../../components/table/table.default';
import moment from 'moment';

export function ProductsEdit() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { id } = useParams()
    const [productsData, setProductsData] = useState({})
    const typeProductsList = typeProducts()
    const unitMeasureList = unitMeasures()
    const [loading, setLoading] = useState(true)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [title, setTitle] = useState('')
    const [statusProduct, setStatusProduct] = useState('')
    const [datasheets, setDataSheets] = useState([])
    const [totalPeriods, setTotalPeriods] = useState(0)
    const [page, setPage] = useState(1)
    const [periodsShow, setPeriodsShow] = useState([])
    const [productsGroupList, setProductsGroupList] = useState([])

    useEffect(() => {        
        // setLoading(true)        
        if(datasheets.length > 0){
            const finish = page * 7 - 1
            const initial = finish - 6

            const selectedPeriods = datasheets.map((period, index) => {
                if(index >= initial && index <= finish) {
                    return period
                }
            }).filter(period => period)

            setPeriodsShow(selectedPeriods)
        }
        // setLoading(false)
        
    }, [ datasheets, page ])

    useEffect(() => {

        async function getProducts() {
            try {

                const getProductsApi = await api.get(`/api/v1/lm/products/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setProductsData(getProductsApi?.data?.data)
                setStatusProduct(getProductsApi?.data?.data?.active)
                setTitle(getProductsApi?.data?.data?.description)

                const getGroupsAPI = await api.get(`api/v1/lm/productsGroup`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setProductsGroupList(getGroupsAPI?.data?.data)

                try {
                    const getAllDatasheetsOfProduct = await api.get(`/api/v1/lm/datasheet/products_id/${id}`, {
                        headers: {
                            authorization: `Bearer ${userData[0].token}`
                        }
                    })
                    setDataSheets(getAllDatasheetsOfProduct?.data?.data?.map((data) => {
                        return {
                            ...data,
                            observation: data.observation ? data.observation : '-',
                            startDate: moment(data.startDate).format('DD/MM/YYYY'),
                            costDate: moment(data.costDate).format('DD/MM/YYYY'),
                            cost: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(data?.cost)
                        }
                    }))
                    let totalItemsGroup = getAllDatasheetsOfProduct?.data?.data?.length / 7
                    if(totalItemsGroup % 1 !== 0){
                        totalItemsGroup = parseInt(totalItemsGroup) >= totalItemsGroup  ? totalItemsGroup : parseInt(totalItemsGroup) + 1
                    }
                    setTotalPeriods(totalItemsGroup)
                    
                } catch (error) {}

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getProducts()

    }, [])

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/lm/products/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/products')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'typeProduct', value: productsData?.typeProduct, required: true, type: 'string' },
            { name: 'description', value: productsData?.description, required: true, type: 'string' },
            { name: 'unitMeasure', value: productsData?.unitMeasure, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.put(`api/v1/lm/products/${id}`, {
                description: productsData?.description,
                externalId: productsData?.externalId ? productsData?.externalId : undefined,
                productsGroup_id: productsData?.productsGroup_id ? productsData?.productsGroup_id : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/products')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function alterStatus() {
        const status = !statusProduct
        setStatusProduct(status)
        
        try {

            await api.put(`api/v1/lm/products/alterStatus/${id}`, {
                status: status
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText(`${status === false ? 'Produto inativado com sucesso' : 'Produto ativado com sucesso'}`)
            setShowNotificationModalSuccess(true)
            setStatusProduct(status)

        } catch (error) {
            setShowModificationModal(true)
            if (responseError(error).length > 0) {
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalText('Erro inesperado')
            }
            setLoading(false)
            setShowNotificationModalSuccess(false)
        }
    }

    return (
        <>
            <ScreenModal title={'Exclusão de Produto'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de produto - ${title}`} />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader />
                        :
                        <>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput text={'Tipo do produto '} required={true}>
                                        <InputAutoComplete
                                            width={80}
                                            data={typeProductsList}
                                            optionList={["name"]}
                                            selectedLabel={'name'}
                                            value={productsData?.typeProduct ? typeProductsList.filter(item => item?.typeProduct == productsData?.typeProduct)[0]?.name : ''}
                                            preSelectedValue={productsData?.typeProduct ? typeProductsList.filter(item => item?.typeProduct == productsData?.typeProduct)[0]?.name : ''}
                                            id='typeProduct'
                                            onChange={(e) => { setProductsData((prev => ({ ...prev, typeProduct: e?.typeProduct, code: '' }))) }}
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Código'} required={true}>
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={productsData?.code ? productsData?.code : typeProductsList?.filter(item => item?.typeProduct == productsData?.typeProduct)[0]?.codeProduct}
                                            id='code'
                                            onChange={(e) => setProductsData((prev) => ({ ...prev, code: justNumber(e.target.value) }))}
                                            charLimit={6}
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Descrição'} required={true}>
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={productsData?.description ? productsData?.description : ''}
                                            id='description'
                                            onChange={(e) => setProductsData((prev => ({ ...prev, description: e.target.value })))}
                                            charLimit={255}
                                        />
                                    </LabelInput>
                                    <LabelInput 
                                        text={'Unidade de Medida'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir como é mensurável o produto.'}
                                    >
                                        <InputAutoComplete
                                            width={80}
                                            data={unitMeasureList}
                                            optionList={["unitMeasure"]}
                                            selectedLabel={'unitMeasure'}
                                            value={productsData ? unitMeasureList.filter(item => item?.id == productsData?.unitMeasure)[0]?.unitMeasure : ''}
                                            preSelectedValue={unitMeasureList.filter(item => item?.id == productsData?.unitMeasure)[0]?.unitMeasure}
                                            id='unitMeasure'
                                            disabled={true}
                                            onChange={(e) => setProductsData((prev => ({ ...prev, unitMeasure: e.id })))}
                                        />
                                    </LabelInput>
                                    <LabelInput 
                                        text={'Custo'} 
                                        showIconInfo={true}
                                        messageIconInfo={'Definido com base no custo da última compra desse produto.'}
                                    >
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={productsData?.cost ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(productsData?.cost) : ''}
                                            id='cost'
                                            disabled={true}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Código antigo'} >
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={productsData?.externalId ? productsData?.externalId : ''}
                                            id='externalId'
                                            onChange={(e) => setProductsData((prev => ({ ...prev, externalId: e.target.value })))}
                                            charLimit={255}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Lançado em'}>
                                        <Input
                                            type={'text'}
                                            width={80}
                                            value={productsData?.created_at ? moment(productsData?.created_at, 'YYYY-MM-DD')?.utc(false)?.format('DD/MM/YYYY') : ''}
                                            id='convertUnitMeasure'
                                            disabled={true}
                                            charLimit={255}
                                        />
                                    </LabelInput>
                                    <LabelInput 
                                        text={'Grupo do produto'}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir qual o grupo que o produto se encaixa.'}
                                    >
                                        <InputAutoComplete
                                            data={productsGroupList}
                                            onChange={(e) => setProductsData((prev => ({ ...prev, productsGroup_id: e.id })))}
                                            value={productsData?.productsGroup_id ? productsGroupList?.find(item => item?.id == productsData?.productsGroup_id)?.description : ''}
                                            preSelectedValue={productsData?.productsGroup_id ? productsGroupList?.find(item => item?.id == productsData?.productsGroup_id)?.description : ''}
                                            optionList={['id', 'description']}
                                            selectedLabel={'description'}
                                            width={80}
                                        />
                                    </LabelInput>
                                    {
                                        (userData[0]?.permissions?.indexOf('lm_products:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <div className='flex flex-col w-full items-center lg:items-start justify-center mt-7'>
                                            <Tippy content={<span>{statusProduct ? 'Desativar' : 'Ativar'}</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>
                                                <div>
                                                    <Toggle status={statusProduct ? true : false} onClick={(e) => alterStatus()}/>
                                                </div>
                                            </Tippy>
                                        </div>
                                    }
                                </form>
                                <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <Button
                                        onClick={() => navigate('/products')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    <div className='flex gap-2'>
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_products:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                approval={false}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_products:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                datasheets.length > 0 && 
                                <>
                                    <hr className='w-full border border-hoverLight'/>
                                    <div className='w-full shadow-2xl rounded-md'>
                                        <TableDefault
                                            removeFilter={true}
                                            title={[
                                                {
                                                    name: "Versão",
                                                    original_name: 'version_id'
                                                },
                                                {
                                                    name: "Data início",
                                                    original_name: 'startDate',
                                                    type: 'date'
                                                },
                                                {
                                                    name: "Custo",
                                                    original_name: 'cost'
                                                },
                                                {
                                                    name: "Observação",
                                                    original_name: 'observation'
                                                },
                                                {
                                                    name: "Custo Alterado",
                                                    original_name: 'costDate',
                                                    type: 'date'
                                                },
                                            ]}
                                            datasheetMark={true}
                                            collumns={['version_id', 'startDate', 'cost', 'observation', 'costDate']}
                                            data={periodsShow}
                                            onClick={(e) => { navigate(`/datasheetEdit/${e.id}/${moment(e.costDate, 'DD/MM/YYYY')?.utc(false)?.format('YYYY-MM-DD')}`) }}
                                        />
                                        <div className='flex gap-2 justify-end mt-1 p-2 items-center'>
                                        {
                                            Array.apply(null, Array(totalPeriods)).map((item, index) => {

                                                const button = <button 
                                                    className={`text-xs font-semibold rounded-md border border-secondaryDefaultLight p-1 w-6 hover:bg-gray-300 ${index+1 == page? 'bg-primaryDefaultLight text-white' : 'bg-white'}`}
                                                    onClick={() => setPage(index + 1)}
                                                >{index + 1}</button>
                                                let existsPoints = false
                                                let showPointers = false

                                                if(totalPeriods < 5){
                                                    return button
                                                }else{
                                                    existsPoints = true
                                                    if(index == 0 || index + 1 == totalPeriods){
                                                        return button
                                                    }else if( page + 2 > index + 1 && page - 2 < index + 1){
                                                        return button
                                                    }else{
                                                        if (page + 2 < index + 1 || page - 2 > index + 1){
                                                            showPointers = true
                                                        }
                                                        if (existsPoints && showPointers == false){
                                                            return <a className='flex items-center'>...</a>
                                                        }
                                                    }
                                                }
                                                    
                                            })
                                        }
                                        </div>      
                                    </div>
                                </>
                            }
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}