//hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
//services
import { api } from "../../../services/api/api";
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from "../../../utils/validators/justNumber";
import { validateFields } from '../../../utils/form.validator'
import { TableDefault } from "../../../components/table/table.default";

export function TaxRegimeEdit() {

    const { userData, selectedCompany } = useAuth()
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [regimeTributaryData, setRegimeTributaryData] = useState('')
    const [icmsTable, setIcmsTable] = useState([])
    const [totalPeriods, setTotalPeriods] = useState(0)
    const [page, setPage] = useState(1)
    const [icmsShow, setIcmsShow] = useState([])

    const optionsTaxation = [
        {
            id: 1,
            name: 'Simples Nacional'
        },
        {
            id: 2,
            name: 'Lucro Presumido'
        },
        {
            id: 3,
            name: 'Lucro Real'
        }
    ]

    useEffect(() => {
        async function getRegimeTributaryAPI() {
            setLoading(true)
            try {
                const getRegimeTributaryData = await api.get(`/api/v1/lm/regimeTributary/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setRegimeTributaryData(getRegimeTributaryData?.data?.data)

                const getTableICMS = await api.get(`/api/v1/lm/icmsTable/${selectedCompany?.addressState}`, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const formatData = getTableICMS?.data?.data?.map(item => {
                    return {
                        ...item,
                        icms: `${item?.icms}%`
                    }
                })
                setIcmsTable(formatData)
                
                let totalItemsGroup = formatData.length / 7
                if(totalItemsGroup % 1 !== 0){
                    totalItemsGroup = parseInt(totalItemsGroup) >= totalItemsGroup  ? totalItemsGroup : parseInt(totalItemsGroup) + 1
                }
                setTotalPeriods(totalItemsGroup)

                setLoading(false)
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
                }
            }
        }

        getRegimeTributaryAPI()
    }, [])

    useEffect(() => {        
        setLoading(true)        
        if(icmsTable.length > 0){
            const finish = page * 7 - 1
            const initial = finish - 6

            const selectedICMS = icmsTable.map((item, index) => {
                if(index >= initial && index <= finish) {
                    return item
                }
            }).filter(item => item)

            setIcmsShow(selectedICMS)
        }
        setLoading(false)
        
    }, [ icmsTable, page ])

    async function handleDelete() {
        setLoading(true)
        try {
            await api.delete(`/api/v1/lm/regimeTributary/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/taxRegime')
            setShowUniversalModal(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'startDate', value: regimeTributaryData?.startDate, required: true, type: 'string' },
            { name: 'taxation', value: regimeTributaryData?.taxation, required: true, type: 'string' },
            { name: 'simplePercentage', value: regimeTributaryData?.simplePercentage, required: regimeTributaryData?.taxation == 1 && true, type: 'string' },
            { name: 'pis', value: regimeTributaryData?.pis, required: true, type: 'string' },
            { name: 'cofins', value: regimeTributaryData?.cofins, required: true, type: 'string' },
            { name: 'ircs', value: regimeTributaryData?.ircs, required: true, type: 'string' },
            { name: 'iva', value: regimeTributaryData?.iva, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/lm/regimeTributary', {
                startDate: regimeTributaryData?.startDate,
                taxation: regimeTributaryData?.taxation,
                simplePercentage: regimeTributaryData?.simplePercentage ? regimeTributaryData?.simplePercentage : undefined,
                pis: regimeTributaryData?.pis,
                cofins: regimeTributaryData?.cofins,
                ircs: regimeTributaryData?.ircs,
                iva: regimeTributaryData?.iva
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/taxRegime')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <ScreenModal title={'Exclusão de Regime Tributário'} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div>
                        <Title text={'Edição de Regime Tributário'}></Title>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader />
                        :
                        <>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput 
                                        text={'Tributação'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir tributação para o regime tributário.'}
                                    >
                                        <InputAutoComplete
                                            width={80}
                                            data={optionsTaxation}
                                            optionList={["id", "name"]}
                                            selectedLabel={'name'}
                                            value={optionsTaxation.filter(item => item.id == regimeTributaryData?.taxation)[0]?.name}
                                            id='taxation'
                                            onChange={(e) => { setRegimeTributaryData((prev) => ({ ...prev, taxation: e.id })) }}
                                        />
                                    </LabelInput>
                                    {
                                        regimeTributaryData?.taxation == 1 ?
                                        <>
                                            <LabelInput text={'Simples Nacional %'}>
                                                <Input
                                                    type={'number'}
                                                    width={80}
                                                    value={regimeTributaryData?.simplePercentage ? regimeTributaryData?.simplePercentage : 0}
                                                    id='simplePercentage'
                                                    onChange={(e) => { setRegimeTributaryData((prev) => ({ ...prev, simplePercentage: e.target.value })) }}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Data Inicial'} required={true}>
                                                <Input
                                                    type={'month'}
                                                    width={80}
                                                    value={moment(regimeTributaryData?.startDate).utc(false).format('YYYY-MM')}
                                                    id='startDate'
                                                    onChange={(e) => setRegimeTributaryData((prev) => ({ ...prev, startDate: e.target.value }))}
                                                />
                                            </LabelInput>
                                        </>
                                        :
                                        <>
                                            <LabelInput text={'Data Inicial'} required={true}>
                                                <Input
                                                    type={'month'}
                                                    width={80}
                                                    value={moment(regimeTributaryData?.startDate).utc(false).format('YYYY-MM')}
                                                    id='startDate'
                                                    onChange={(e) => setRegimeTributaryData((prev) => ({ ...prev, startDate: e.target.value }))}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'PIS %'}>
                                                <Input
                                                    type={'number'}
                                                    width={80}
                                                    value={regimeTributaryData?.pis ? regimeTributaryData?.pis : 0}
                                                    id='pis'
                                                    onChange={(e) => { setRegimeTributaryData((prev) => ({ ...prev, pis: e.target.value })) }}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'COFINS %'}>
                                                <Input
                                                    type={'number'}
                                                    width={80}
                                                    value={regimeTributaryData?.cofins ? regimeTributaryData?.cofins : 0}
                                                    id='cofins'
                                                    onChange={(e) => { setRegimeTributaryData((prev) => ({ ...prev, cofins: e.target.value })) }}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'IR/CS %'}>
                                                <Input
                                                    type={'number'}
                                                    width={80}
                                                    value={regimeTributaryData?.ircs ? regimeTributaryData?.ircs : 0}
                                                    id='ircs'
                                                    onChange={(e) => { setRegimeTributaryData((prev) => ({ ...prev, ircs: e.target.value })) }}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'IVA %'}>
                                                <Input
                                                    type={'number'}
                                                    width={80}
                                                    value={regimeTributaryData?.iva ? regimeTributaryData?.iva : 0}
                                                    id='iva'
                                                    onChange={(e) => { setRegimeTributaryData((prev) => ({ ...prev, iva: e.target.value })) }}
                                                />
                                            </LabelInput>
                                        </>
                                    }
                                </form>
                                <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                    <Button
                                        onClick={() => navigate('/taxRegime')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    <div className='flex gap-2'>
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_regimeTributary:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                approval={false}
                                                onClick={() => setShowUniversalModal(true)}
                                            >Excluir
                                            </Button>
                                        }
                                        {
                                            (userData[0]?.permissions?.indexOf('lm_regimeTributary:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                            <Button
                                                shadow={true}
                                                onClick={() => handleSubmit()}
                                            >Editar
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                icmsTable.length > 0 && regimeTributaryData?.taxation != 1 &&
                                <>
                                    <hr className='w-full border border-hoverLight'/>
                                    <div className='w-full shadow-2xl rounded-md lg:pb-20 xl:pb-0'>
                                        <TableDefault
                                            removeFilter={true}
                                            descriptionHeader={'Tabela ICMS'}
                                            title={[
                                                {
                                                    name: "UF Origem",
                                                    original_name: 'ufOrigin'
                                                },
                                                {
                                                    name: "UF Destino",
                                                    original_name: 'ufDestiny'
                                                },
                                                {
                                                    name: "ICMS",
                                                    original_name: 'icms'
                                                },
                                                {
                                                    name: "Data Inicial",
                                                    original_name: 'startDate',
                                                    type: 'date'
                                                }
                                            ]}
                                            collumns={['ufOrigin', 'ufDestiny', 'icms', 'startDate']}
                                            data={icmsShow}
                                            onClick={(e) => {}}
                                        />
                                        <div className='flex gap-2 justify-end mt-1 p-2 items-center'>
                                        {
                                            Array.apply(null, Array(totalPeriods)).map((item, index) => {

                                                const button = <button 
                                                    className={`text-xs font-semibold rounded-md border border-secondaryDefaultLight p-1 w-6 hover:bg-gray-300 ${index+1 == page? 'bg-primaryDefaultLight text-white' : 'bg-white'}`}
                                                    onClick={() => setPage(index + 1)}
                                                >{index + 1}</button>
                                                let existsPoints = false
                                                let showPointers = false

                                                if(totalPeriods < 5){
                                                    return button
                                                }else{
                                                    existsPoints = true
                                                    if(index == 0 || index + 1 == totalPeriods){
                                                        return button
                                                    }else if( page + 2 > index + 1 && page - 2 < index + 1){
                                                        return button
                                                    }else{
                                                        if (page + 2 < index + 1 || page - 2 > index + 1){
                                                            showPointers = true
                                                        }
                                                        if (existsPoints && showPointers == false){
                                                            return <a className='flex items-center'>...</a>
                                                        }
                                                    }
                                                }
                                                    
                                            })
                                        }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}