// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
import { useParams } from 'react-router-dom'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { FaEdit } from 'react-icons/fa'

export function ClientsEdit() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { userData, selectedCompany } = useAuth()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    
    // Fields
    const [ customerData, setCustomerData ] = useState({})
    const [companiesList, setCompaniesList] = useState([])
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [alterColor, setAlterColor] = useState(false)
    const [nameCompany, setNameCompany] = useState('')
    const [usersLinkeds, setUsersLinkeds] = useState([])
    const [companiesLinkeds, setCompaniesLinkeds] = useState([])

    useEffect(() => {

        async function getLinkeds(){
            try {
                
                const getCustomersAPI = await api.get(`/api/v1/customers/linkeds/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setUsersLinkeds(getCustomersAPI?.data?.data?.users.map(item => {
                    return {
                        ...item,
                        functions: [{func: <FaEdit onClick={() => navigate(`/usersClientsEdit/${item?.id}`)}/>}]
                    }
                }))
                setCompaniesLinkeds(getCustomersAPI?.data?.data?.companies.map(company => {
                    return {
                        ...company,
                        cnpj: cnpjMask(company?.cnpj),
                        fantasyName: company?.fantasyName ? company?.fantasyName : ' - ',
                        functions: [{func: <FaEdit onClick={() => navigate(`/companiesEdit/${company?.id}`)}/>}]
                    }
                }))
                
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getCustomers(){
            try {
                
                const getCustomersAPI = await api.get('/api/v1/customers', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })

                setCustomerData(getCustomersAPI?.data?.data.filter(customer => {
                    if (customer?.id == id){
                        setNameCompany(customer.businessName)
                        return customer
                    }
                })[0])
                
                setLoading(false)

            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getLinkeds()
        getCustomers()

    }, [])

    async function handleSubmit(){
       
        setLoading(true)

        const requiredFields = [
            { name: 'businessName', value: customerData?.businessName, required: true, type: 'string' },
            { name: 'module', value: customerData?.modules, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (customerData?.email){
            if (!isValidEmail) {
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Email inválido')
                return setLoading(false)
            }
        }

        try {
            
            await api.put(`api/v1/customers/${id}`, {
                businessName: customerData?.businessName,
                fantasyName: customerData?.fantasyName ? customerData.fantasyName : undefined,
                cnpj: customerData?.cnpj ? customerData.cnpj?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                email: customerData?.email ? customerData.email : undefined,
                modules: customerData.modules ? customerData?.modules : undefined,
                phone: customerData?.phone ? customerData?.phone : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            if (alterColor){
                if (id == selectedCompany?.id){
                    document.documentElement.style.setProperty('--companyColor', customerData?.colorIdentifier ? customerData?.colorIdentifier : '#3162be')
                    localStorage.setItem('companyColor',  customerData?.colorIdentifier ? customerData.colorIdentifier : '#3162be')
                }
            }

            setAlterColor(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/clients')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    async function handleDelete(){
        setLoading(true)

        try {

            await api.delete(`/api/v1/customers/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/clients')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const modulesList = [
        {
            id: 1,
            description: 'Ambos',
            value: 'fc,lm',
        },
        {
            id: 2,
            description: 'Fluxo de caixa',
            value: 'fc',
        },
        {
            id: 3,
            description: 'Leitura de margem',
            value: 'lm',
        }
    ]

    const tableHead = [
        {
            name: 'Código',
            original_name: "id"
        },
        {
            name: 'Nome',
            original_name: "fullname"
        },
        {
            name: 'E-mail',
            original_name: "email"
        }
    ]

    const tableHeadCompany = [
        {
            name: "Código",
            original_name: 'id'
        },
        {
            name: "Razão Social",
            original_name: 'businessName'
        },
        {
            name: "Nome Fantasia",
            original_name: 'fantasyName'
        },
        {
            name: "CNPJ",
            original_name: 'cnpj'
        },
    ]

    return (
        <>
            <ScreenModal title={'Exclusão de Cliente'} visible={showModal} width={400} height={200} >
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o cliente ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Edição de Cliente - ${nameCompany}`} />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader/>
                        :
                        <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                            <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                <LabelInput text={'Razão social *'}>
                                    <Input 
                                        type={'text'} 
                                        width={80}
                                        value={customerData?.businessName} 
                                        id='businessName' 
                                        onChange={(e) => setCustomerData((prev) => ({...prev, businessName: e.target.value}))}
                                        charLimit={200} 
                                    />
                                </LabelInput>
                                <LabelInput text={'Nome fantasia'}>
                                    <Input 
                                        type={'text'} 
                                        width={80}
                                        value={customerData?.fantasyName} 
                                        id='name' 
                                        onChange={(e) => setCustomerData(prev => ({...prev, fantasyName: e.target.value}))}
                                        charLimit={200} 
                                    />
                                </LabelInput>
                                <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                    <Input type={'text'} width={80} value={customerData?.email} id='email' onChange={(e) => {
                                        const isValid = validateEmail(e.target.value)
                                        if (isValid === true) {
                                            setIsValidEmail(true)
                                        } else {
                                            setIsValidEmail(false)
                                        }
                                        setCustomerData(prev => ({...prev, email: e.target.value}))}} charLimit={255} />
                                </LabelInput>
                                <LabelInput text={'CNPJ'}>
                                    <Input type={'text'} 
                                        value={cnpjMask(customerData?.cnpj)} 
                                        id='cnpj' 
                                        width={80}
                                        onChange={(e) => setCustomerData(prev => ({...prev, cnpj: e.target.value}))} 
                                        charLimit={18} 
                                    />
                                </LabelInput>                                
                                <LabelInput 
                                    text={'Módulo *'}
                                    showIconInfo={true}
                                    messageIconInfo={'Definir qual módulo o cliente vai poder acessar.'}
                                >
                                    <InputAutoComplete
                                        preSelectedValue={customerData?.modules ? modulesList.filter(e => { return e?.value == customerData?.modules})[0]?.description : ''}
                                        value={modulesList.filter(e => { return e?.value == customerData?.modules})[0]?.description}
                                        selectedLabel={'description'} id='module'
                                        placeHolder={' - '}
                                        data={modulesList} optionList={['description']}
                                        onChange={e => {
                                            {
                                                setCustomerData(prev => ({...prev, modules: e.id != 1 ? [ e?.value ] : [ 'fc', 'lm' ] }))
                                            }
                                        }}>
                                    </InputAutoComplete>
                                </LabelInput>
                                <LabelInput text={'Telefone'}>
                                    <Input charLimit={12} type={'text'} width={80} onChange={(e) => setCustomerData(prev => ({...prev, phone: justNumber(e.target.value)}))} value={customerData?.phone}/>
                                </LabelInput>
                            </form>
                            <div className='mt-7 w-full'>
                                <p className='font-normal flex md:flex text-md text-primaryDefaultLight dark:text-primaryDefaultLight'>Usuários vinculados</p>
                                <div className='hidden sm:flex mt-5'>
                                    <TableDefault
                                        data={usersLinkeds}
                                        onClick={(e) => navigate(`/usersClientsEdit/${e?.id}`)}
                                        title={tableHead}
                                        collumns={["id", "fullname", "email"]}
                                    />
                                </div>
                                <div className='flex sm:hidden mt-5'>
                                    <TableMobile
                                        collumns={["id", "fullname", "email"]}
                                        data={usersLinkeds}
                                        title={tableHead}
                                    />
                                </div>
                            </div>
                            <div className='mt-5 w-full'>
                                <p className='font-normal flex md:flex text-md text-primaryDefaultLight dark:text-primaryDefaultLight'>Empresas vinculadas</p>
                                <div className='hidden sm:flex mt-5'>
                                    <TableDefault
                                        data={companiesLinkeds}
                                        onClick={(e) => navigate(`/companiesEdit/${e?.id}`)}
                                        title={tableHeadCompany}
                                        collumns={["id", "businessName", "fantasyName", "cnpj"]}
                                    />
                                </div>
                                <div className='flex sm:hidden mt-5'>
                                    <TableMobile
                                        collumns={["id", "businessName", "fantasyName", "cnpj"]}
                                        data={companiesLinkeds}
                                        title={tableHeadCompany}
                                    />
                                </div>
                            </div>
                            <div className='mt-8 flex flex-col md:flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center sm:justify-between'>
                                <Button
                                    onClick={() => navigate(-1)}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <div className='flex gap-2'>
                                    {
                                        (userData[0]?.permissions?.indexOf('customers:delete') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            approval={false}
                                            onClick={() => setShowUniversalModal(true)}
                                        >Excluir
                                        </Button>
                                    }
                                    {
                                        (userData[0]?.permissions?.indexOf('customers:update') > -1 || userData[0]?.typeAccess_id == 1) &&
                                        <Button
                                            shadow={true}
                                            onClick={() => handleSubmit()}
                                        >Editar cliente
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}