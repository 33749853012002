import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Title } from '../../../components/titlePages/title';
import { Body } from '../../../components/container/Body';
import { Container } from '../../../components/container/container';
import { LoadingPulse } from '../../../components/loaders/loadingPulse';
import { TitlePage } from "../../../components/titlePages/title.page";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { LabelInput } from "../../../components/label/label.input";
import { useAuth } from '../../../contexts/useAuth';
import { useThemeContext } from '../../../contexts/themeContext';
import { api } from "../../../services/api/api";
import moment from "moment";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/buttons/button.default";
import Multiselect from "../../../components/selects/mult.select";
import { validateFields } from '../../../utils/form.validator';
import tableToExcel from "../../../utils/tableToExcel";
import { BsCardChecklist } from "react-icons/bs";
import { filterParams } from '../../../utils/filtersParams';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaAngleRight, FaEye, FaFileExcel } from "react-icons/fa";
import { ScreenModal } from '../../../components/modals/notification/screenModal';
import { v4 as uuid } from 'uuid';

export function ContributionMargin(){

    const navigate = useNavigate()
    const { userData, selectedCompany, selectedPeriod } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([selectedCompany])
    const [selectedStartDate, setSelectedStartDate] = useState(moment(selectedPeriod?.value, 'MM/YYYY')?.add(-2, 'month').startOf('month').format('YYYY-MM'))
    const [selectedFinishDate, setSelectedFinishDate] = useState(moment(selectedPeriod?.value, 'MM/YYYY').endOf('month').format('YYYY-MM'))
    const [data, setData] = useState()
    const [dataHistory, setDataHistory] = useState()
    const [filterList, setFiltersList] = useState({})
    const [filterListHistory, setFiltersListHistory] = useState({})
    const [filterParam, setFilterParam] = useState({})
    const objectParamsAllTrue = filterParams.reduce((obj, item) => {
        obj[item.id] = true
        return obj
    }, {})
    const [selectedRow, setSelectedRow] = useState('')
    const [selectedFilters, setSelectedFilters] = useState([])
    const [selectedTotal, setSelectedTotal] = useState([])
    const [loadingFilter, setLoadingFilter] = useState(false)
    const [dataFilters, setDataFilters] = useState([])
    const [view, setView] = useState('accumulatedAllPerMonth')
    const [dateInitial, setDateInitial] = useState('')

    const [selectedAllCompany, setSelectedAllCompany] = useState({value: 2, description: 'Personalizado'})
    useEffect(() => {
        async function getCompanies(){
            try {
                const getCompaniesAPI = await api.get(`api/v1/companies/readCompaniesSelected`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setCompanies(getCompaniesAPI?.data?.data)
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    navigate('/')
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getCompanies()
    }, [])

    useEffect(() => {
        getData()
    }, [view])

    async function getData(){
        setLoading(true)

        const params = []
        selectedStartDate && params?.push(`startDate=${selectedStartDate}`)
        selectedFinishDate && params?.push(`finishDate=${selectedFinishDate}`)
        selectedCompanies && params?.push(`companies=${selectedCompanies?.map(item => {return item?.id}).join(',')}`)

        const paramFilter = []
        Object.keys(filterParam).forEach(item => filterParam[item] ? paramFilter.push(`${item}=${filterParam[item]}`) : null)

        try {

            const getParamsApi = await api.get(`api/v1/lm/filterParams`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setFiltersList(getParamsApi?.data?.data[0]?.config ? structuredClone(getParamsApi?.data?.data[0]?.config) : objectParamsAllTrue)
            setFiltersListHistory(getParamsApi?.data?.data[0]?.config ? structuredClone(getParamsApi?.data?.data[0]?.config) : objectParamsAllTrue)

            const getDataApi = await api.get(`api/v1/lm/sales/contributionMargin?view=${view}&${params.length > 0 ? params.join('&') : ''}${paramFilter.length > 0 ? '&'+paramFilter.join('&') : '' }`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            const formatData = await functionForFormatData(getDataApi?.data?.data?.length > 0 ? getDataApi?.data?.data : [])

            const formatNewData = formatData?.length > 0 ? formatData?.map(item => {
                return {
                    ...item,
                    percentageFreight: ((item?.freight * 100) / item?.totalSale)?.toFixed(2),
                    icms: ((item?.valueIcms * 100) / item?.factorMeasurable)?.toFixed(2),
                    pis: ((item?.valuePis * 100) / item?.factorMeasurable)?.toFixed(2),
                    cofins: ((item?.valueCofins * 100) / item?.factorMeasurable)?.toFixed(2),
                    simplePercentage: ((item?.valueSimple * 100) / item?.factorMeasurable)?.toFixed(2),
                    ircs: ((item?.valueIrcs * 100) / item?.factorMeasurable)?.toFixed(2),
                    iva: ((item?.valueIva * 100) / item?.factorMeasurable)?.toFixed(2),
                    percentageChange: ((item?.totalChange * 100) / item?.factorMeasurable)?.toFixed(2),
                }
            }) : []
            
            const objTotal = await functionForFormatTotal(formatNewData)

            setSelectedTotal(objTotal)
            setDataHistory(formatData)
            setData(formatNewData)
            setDataFilters([formatNewData])

            setLoading(false)

        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                return
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                // navigate('/')
                return setShowNotificationModalText(`Não existe nenhuma venda no período selecionado.`)
            }
        }
    }

    function headerTable(description, colSpan) {
        return (
            <th className='left-0 top-0 bg-primaryDefaultLight' colSpan={colSpan ? colSpan : 1}>
                <div className='min-w-[10rem] z-5 py-1 text-sm'>
                    {description}
                </div>
            </th>
        )
    }

    function rowTable(value, index, border, total) {
        return (
            <td className={`select-none m-0 z-3 left-0 ${border ? `${border == 'r' ? 'border-0 border-r-2 border-r-gray-300' : border == 'l' ? 'border-0 border-l-2 border-l-gray-300': ''}` : 'border-0 border-r-2 border-r-gray-300'} ${index % 2 == 0 && !total ? 'bg-white dark:bg-gray-500' : total ? 'bg-primaryDefaultLight text-white font-semibold py-1' : 'bg-[#ececec] dark:bg-gray-600'} z-3`}>
                <div className='min-w-[10rem] border-0 flex items-center text-sm justify-center dark:text-white text-center'>{value}</div>
            </td>
        )
    }

    async function handleRow(item){
        setSelectedRow(item)
    }

    async function validatorFields(){

        const requiredFields = [
            { name: 'startDate', value: selectedStartDate, required: true, type: 'string' },
            { name: 'finishDate', value: selectedFinishDate, required: true, type: 'string' }
        ]

        let startElement = document.getElementById('startDate')
        let finishElement = document.getElementById('finishDate')
        let companiesElement = document.getElementById('labelCompanies')

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!selectedCompanies || selectedCompanies?.length == 0){
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            companiesElement.style.color = 'red'
            return setShowNotificationModalText('Campos obrigatórios não preenchidos')
        }

        if (moment(selectedFinishDate)?.isBefore(selectedStartDate)) {
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            finishElement.style.border = '1px solid red'
            startElement.style.border = '1px solid red'
            return setShowNotificationModalText('Data final não pode ser menor ou igual que a data inicial')
        }

        if (selectedAllCompany && selectedAllCompany?.value == 2){
            companiesElement.style.color = ''
        }

        getData()
    }

    async function handleParams(){
        setShowUniversalModal(false)
        try {
            await api.post(`/api/v1/lm/filterParams`, {
                config: filterListHistory
            } ,{
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setFiltersList(structuredClone(filterListHistory))
            setShowModificationModal(true)
            setShowNotificationModalText('Aparência aplicada com sucesso.')
            setShowNotificationModalSuccess(true)

        } catch(error){
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                return
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                navigate('/')
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function addFilter(filter){
        if(selectedRow){
            switch (filter?.filter) {
                case 'client_description':
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('client_description')
                    break;
                case 'saleDate':            
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('saleDate')
                    break;
                case 'businessName': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('businessName')
                    break;
                case 'saleOrder': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('saleOrder')
                    break;
                case 'nf': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('nf')
                    break;
                case 'addressState': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('addressState')
                    break;
                case 'seller_description': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('seller_description')
                break;
                case 'product': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('product')
                    break;
                case 'productsGroup': 
                    setFilterParam({
                        selectedCompany: selectedRow?.companyId ? selectedRow?.companyId : filterParam?.selectedCompany ? filterParam?.selectedCompany : undefined,
                        selectedDate: selectedRow?.saleDate ? selectedRow?.saleDate : filterParam?.selectedDate ? filterParam?.selectedDate : undefined,
                        selectedSaleOrder: selectedRow?.saleOrder ? selectedRow?.saleOrder : filterParam?.selectedSaleOrder ? filterParam?.selectedSaleOrder : undefined,
                        selectedNf: selectedRow?.nf ? selectedRow?.nf : filterParam?.selectedNf ? filterParam?.selectedNf : undefined,
                        selectedClient: selectedRow?.client_id ? selectedRow?.client_id : filterParam?.selectedClient ? filterParam?.selectedClient : undefined,
                        selectedState: selectedRow?.addressState ? selectedRow?.addressState : filterParam?.selectedState ? filterParam?.selectedState : undefined,
                        selectedSeller: selectedRow?.seller_id ? selectedRow?.seller_id : filterParam?.selectedSeller ? filterParam?.selectedSeller : undefined,
                        selectedProduct: selectedRow?.productId ? selectedRow?.productId : filterParam?.selectedProduct ? filterParam?.selectedProduct : undefined,
                        selectedGroupProduct: selectedRow?.productsGroupId ? selectedRow?.productsGroupId : filterParam?.selectedGroupProduct ? filterParam?.selectedGroupProduct : undefined,
                        selectedTotal: selectedRow?.isTotal ? true : filterParam?.isTotal ? true : false
                    })
                    setView('productsGroup')
                    break;
                default:
                    break;
            }

            if (selectedFilters?.length == 0) { setDateInitial(selectedRow?.isTotal ? 'Total' : selectedRow?.saleDate) }
            setSelectedFilters(prev => ([...prev, {filter: filter.filter, description: filter?.description}]))
            setLoadingFilter(true)
            setLoadingFilter(false)
            setSelectedRow(null)
        }else{
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Você precisa selecionar uma linha para aplicar o filtro')
        }
    }

    async function removeLastFilter(){
        let cloneFilters = structuredClone(selectedFilters)
        cloneFilters.pop()

        if (!selectedFilters[cloneFilters?.length - 1]){
            setSelectedFilters(cloneFilters)
            return setView('accumulatedAllPerMonth')
        }

        switch (selectedFilters[cloneFilters?.length - 1]?.filter) {
            case 'client_description':
                setFilterParam(prev => ({...prev, selectedClient: '', selectedState: ''}))
                setSelectedFilters(cloneFilters)
                return setView('client_description')
                break;
            case 'saleDate':
                setFilterParam(prev => ({...prev, selectedDate: dateInitial == 'Total' ? '' : dateInitial}))
                setSelectedFilters(cloneFilters)
                return setView('saleDate')
                break;
            case 'businessName': 
                setFilterParam(prev => ({...prev, selectedCompany: ''}))
                setSelectedFilters(cloneFilters)
                return setView('businessName')
                break;
            case 'saleOrder': 
                setFilterParam(prev => ({...prev, selectedSaleOrder: ''}))
                setSelectedFilters(cloneFilters)
                return setView('saleOrder')
                break;
            case 'nf': 
                setFilterParam(prev => ({...prev, selectedNf: ''}))
                setSelectedFilters(cloneFilters)
                return setView('nf')
                break;
            case 'addressState': 
                setFilterParam(prev => ({...prev, selectedState: ''}))
                setSelectedFilters(cloneFilters)
                return setView('addressState')
                break;
            case 'seller_description': 
                setFilterParam(prev => ({...prev, selectedSeller: ''}))
                setSelectedFilters(cloneFilters)
                return setView('seller_description')
            break;
            case 'product': 
                setFilterParam(prev => ({...prev, selectedProduct: '', selectedGroupProduct: ''}))
                setSelectedFilters(cloneFilters)
                return setView('product')
                break;
            case 'productsGroup': 
                setFilterParam(prev => ({...prev, selectedGroupProduct: ''}))
                setSelectedFilters(cloneFilters)
                return setView('productsGroup')
                break;
            default:
                break;
        }
    }

    async function functionForFormatData(data){
        return data?.map(item => {
            const cpv = (Number(item?.cost?.toFixed(2)) * item?.quantity)?.toFixed(5)
            const cpvFat = item?.factorMeasurable ? (Number(item?.cost?.toFixed(2)) / Number(item?.factorMeasurable))?.toFixed(5) : 0
            const baseTax = item?.factorMeasurable
            const totalSale = item?.discount ? (item?.unitPrice * item?.quantity) - item?.discount : item?.totalSale
            const notValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(0)
            const valueSimple = item?.factorMeasurable ? Number(item?.simplePercentage)?.toFixed(5) && item?.factorMeasurable * (item?.simplePercentage / 100) : 0
            const valueIcms = item?.factorMeasurable ? item?.icms && item?.factorMeasurable * (item?.icms / 100) : 0
            const valuePis = item?.factorMeasurable ? item?.pis && item?.factorMeasurable * (item?.pis / 100) : 0
            const valueCofins = item?.factorMeasurable ? item?.cofins && item?.factorMeasurable * (item?.cofins / 100) : 0
            const valueIrcs = item?.factorMeasurable ? item?.ircs && item?.factorMeasurable * (item?.ircs / 100) : 0
            const valueIva = item?.factorMeasurable ? item?.iva && item?.factorMeasurable * (item?.iva / 100) : 0
            const valueCommission = item?.commission ? item.commission : 0
            const commission = Number(((valueCommission * 100) / item?.totalSale)?.toFixed(2))
            const percentageFreight = item?.freight > 0 ? Number(item?.freight * 100 / totalSale)?.toFixed(2) : 0
            const totalChange = item?.factorMeasurable ? item?.percentageChange && item?.percentageChange * (item?.factorMeasurable / 100) : 0
            
            const priceLiquid = (
                Number(item?.factorMeasurable) -
                Number(totalSale * (commission / 100)) -
                Number(item?.freight) -
                Number(totalChange) -
                Number(valueIcms) -
                Number(valuePis) -
                Number(valueCofins) -
                Number(valueSimple) -
                Number(valueIrcs) -
                Number(valueIva)
            )?.toFixed(2)

            const marginTotal = (
                Number(totalSale) -
                Number(item?.cost) -
                Number(totalSale * (commission / 100)) -
                Number(item?.freight) -
                Number(totalChange) -
                Number(valueIcms) -
                Number(valuePis) -
                Number(valueCofins) -
                Number(valueSimple) -
                Number(valueIrcs) -
                Number(valueIva)
            )?.toFixed(2)
    
            return {
                ...item,
                cpv: cpv == 'NaN' ? 0 : cpv,
                cpvFat: cpvFat == 'NaN' ? 0 : cpvFat,
                baseTax,
                totalSale,
                notValue,
                valueSimple,
                valueIcms,
                valuePis,
                valueCofins,
                valueIrcs,
                valueIva,
                commission,
                valueCommission,
                percentageFreight,
                totalChange,
                priceLiquid,
                marginTotal
            }
        })
    }

    async function functionForFormatTotal(data){

        const objTotal = {}
        Object.keys(data[0])?.map(key => {
            objTotal[key] = 0
        })

        data?.map(item => {
            objTotal.quantity += Number(item?.quantity)
            objTotal.totalSale += Number(item?.totalSale)
            objTotal.unitPrice += Number(item?.unitPrice)
            objTotal.discount += Number(item?.discount)
            objTotal.cost += Number(item?.cost)
            objTotal.commission += Number(item?.commission)
            objTotal.freight += Number(item?.freight)
            objTotal.factorMeasurable += Number(item?.factorMeasurable)
            objTotal.valueSimple += Number(item?.valueSimple)
            objTotal.valueIcms += Number(item?.valueIcms)
            objTotal.valuePis += Number(item?.valuePis)
            objTotal.valueCofins += Number(item?.valueCofins)
            objTotal.valueIrcs += Number(item?.valueIrcs)
            objTotal.valueIva += Number(item?.valueIva)
            objTotal.valueCommission += Number(item?.valueCommission)
            objTotal.totalChange += Number(item?.totalChange)
            objTotal.priceLiquid += Number(item?.priceLiquid)
            objTotal.marginTotal += Number(item?.marginTotal)
            objTotal.cpv += Number(item.cpv)
        })

        const arrayObj = [objTotal]
        const formatObjTotal = arrayObj?.map(item => {
            return {
                ...item,
                isTotal: true,
                cpvFat: item?.factorMeasurable ? (Number(item?.cost) / Number(item?.factorMeasurable))?.toFixed(5) : 0,
                baseTax: item?.factorMeasurable,
                percentageFreight: ((item?.freight * 100) / item?.totalSale)?.toFixed(2),
                commission: ((item?.valueCommission * 100) / item?.totalSale)?.toFixed(2),
                icms: ((item?.valueIcms * 100) / item?.factorMeasurable)?.toFixed(2),
                pis: ((item?.valuePis * 100) / item?.factorMeasurable)?.toFixed(2),
                cofins: ((item?.valueCofins * 100) / item?.factorMeasurable)?.toFixed(2),
                simplePercentage: ((item?.valueSimple * 100) / item?.factorMeasurable)?.toFixed(2),
                ircs: ((item?.valueIrcs * 100) / item?.factorMeasurable)?.toFixed(2),
                iva: ((item?.valueIva * 100) / item?.factorMeasurable)?.toFixed(2),
                percentageChange: ((item?.totalChange * 100) / item?.factorMeasurable)?.toFixed(2),
            }
        })

        return formatObjTotal
    }

    const options = [
        {
            value: 1,
            description: "Todas"
        },
        {
            value: 2,
            description: "Personalizado"
        }
    ]

    return (
        <>
        <ScreenModal title={'Configurar Aparência'}>
            <>
                <div className="flex flex-col flex-wrap max-h-[30rem] gap-2 p-2 items-start justify-start text-sm">
                    {
                        filterParams?.map(params => {
                            if (params?.editable){
                                return (
                                    <div className="p-1 flex flex-row gap-1">
                                        <input type="checkbox" checked={filterListHistory[params?.id]} id={params?.id} value={params?.id} onChange={() => setFiltersListHistory(prev => ({...prev, [params?.id]: !filterListHistory[params?.id]}))}/>
                                        <label for={params?.id}>{params?.description}</label>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div className="flex w-full items-center justify-center pb-2 mt-2">
                    <Button height={7} width={16} onClick={() => handleParams()}>Aplicar</Button>
                </div>
            </>
        </ScreenModal>
            <Container validPeriod_lm={true}>
                <TitlePage>
                    <div className="w-full flex flex-row justify-between items-center">
                        <div className="flex flex-row gap-2 items-center justify-center">
                            <Title text={'Margem de Contribuição'}/>
                            <Tippy
                                content={<span>Exportar Excel</span>}
                                arrow={true}
                                animation='perspective'
                                placement='top'
                                delay={100}>
                                <div>
                                    <FaFileExcel className="text-sm text-primaryDefaultLight cursor-pointer" onClick={() => tableToExcel(`${Number(new Date)}.xls`)}/>
                                </div>
                            </Tippy>                            
                            <a id="link-to-download" href="!#">{}</a>
                        </div>
                        <div className="flex flex-row gap-1 text-xs font-semibold items-center">
                            {
                                selectedFilters?.length > 0 &&
                                <>
                                    <p>{dateInitial == 'Total' ? '[Total]' : `[${moment(dateInitial, 'YYYY-MM')?.format('MM/YYYY')}]`}</p>
                                    <FaAngleRight className="text-sm"/>
                                </>
                            }
                            {                                
                                selectedFilters?.length < 5 ?
                                selectedFilters?.map((filter, index) => {
                                    if (filter?.description == 'Empresa'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedCompany ? `${filter?.description} [${filterParam?.selectedCompany}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Data'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedDate?.length > 7 && (selectedFilters[0]?.filter != 'saleDate' || selectedFilters?.length > 1) ? `${filter?.description} [${moment(filterParam?.selectedDate, 'YYYY-MM-DD')?.utc(false)?.format('DD/MM/YYYY')}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Pedido'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedSaleOrder ? `${filter?.description} [${filterParam?.selectedSaleOrder}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Nota Fiscal'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedNf ? `${filter?.description} [${filterParam?.selectedNf}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Cliente'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedClient ? `${filter?.description} [${filterParam?.selectedClient}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Estado'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedState ? `${filter?.description} [${filterParam?.selectedState}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Representante'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedSeller ? `${filter?.description} [${filterParam?.selectedSeller}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Produto'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedProduct ? `${filter?.description} [${filterParam?.selectedProduct}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                    if (filter?.description == 'Grupo do Produto'){
                                        return (
                                            <>
                                                <p>{filterParam?.selectedGroupProduct ? `${filter?.description} [${filterParam?.selectedGroupProduct}]` : `${filter?.description}`}</p>
                                                {selectedFilters?.length != index + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                            </>
                                        )
                                    }
                                })
                                :
                                <>
                                    <p>...</p>
                                    {
                                        selectedFilters?.slice(selectedFilters?.length - 5, selectedFilters?.length)?.map((filter, indexL) => {
                                            const splitFilters = selectedFilters?.slice(selectedFilters?.length - 5, selectedFilters?.length)?.length
                                            if (filter?.description == 'Empresa'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedCompany ? `${filter?.description} [${filterParam?.selectedCompany}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Data'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedDate?.length > 7 && (selectedFilters[0]?.filter != 'saleDate' || selectedFilters?.length > 1) ? `${filter?.description} [${moment(filterParam?.selectedDate, 'YYYY-MM-DD')?.utc(false)?.format('DD/MM/YYYY')}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Pedido'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedSaleOrder ? `${filter?.description} [${filterParam?.selectedSaleOrder}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Nota Fiscal'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedNf ? `${filter?.description} [${filterParam?.selectedNf}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Cliente'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedClient ? `${filter?.description} [${filterParam?.selectedClient}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Estado'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedState ? `${filter?.description} [${filterParam?.selectedState}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Representante'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedSeller ? `${filter?.description} [${filterParam?.selectedSeller}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Produto'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedProduct ? `${filter?.description} [${filterParam?.selectedProduct}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                            if (filter?.description == 'Grupo do Produto'){
                                                return (
                                                    <>
                                                        <p>{filterParam?.selectedGroupProduct ? `${filter?.description} [${filterParam?.selectedGroupProduct}]` : `${filter?.description}`}</p>
                                                        {splitFilters != indexL + 1 ? <FaAngleRight className="text-sm"/> : ''}
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <LoadingPulse/>
                        :
                        <>
                            <div className="hidden sm:flex sm:flex-wrap 2xl:flex-row md:gap-3 2xl:gap-0 w-full justify-between">
                                <div className="flex sm:flex-wrap 2xl:flex-row items-center sm:gap-2 ld:gap-10">
                                    <LabelInput text={'Data Início *'}>
                                        <Input
                                            type={'month'}
                                            value={selectedStartDate ? selectedStartDate : ''}
                                            width={'160px'}
                                            onChange={(e) => {
                                                setSelectedStartDate(e?.target?.value)
                                            }}
                                            id={'startDate'}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data Final *'}>
                                        <Input
                                            type={'month'}
                                            value={selectedFinishDate ? selectedFinishDate : ''}
                                            width={'160px'}
                                            onChange={(e) => {
                                                setSelectedFinishDate(e?.target?.value)
                                            }}
                                            id={'finishDate'}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Selecionar empresas'}>
                                        <InputAutoComplete
                                            data={options}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            onChange={(e) => {
                                                if (e?.value == 1){
                                                    setSelectedCompanies(companies)
                                                    setSelectedAllCompany(e)
                                                } else {
                                                    setSelectedAllCompany(e)
                                                }
                                            }}
                                            preSelectedValue={selectedAllCompany ? options?.find(item => item?.value == selectedAllCompany?.value)?.description : ''}
                                            value={selectedAllCompany ? options?.find(item => item?.value == selectedAllCompany?.value)?.description : ''}
                                            width={40}
                                        />
                                    </LabelInput>
                                    {
                                        selectedAllCompany?.value == 2 &&
                                        <LabelInput text={'Empresas *'} id={'labelCompanies'} icon={<BsCardChecklist className='flex items-center ml-2 text-lg'/>}>                                        
                                            <Multiselect
                                                items={companies}
                                                mult                                                 
                                                onChange={e => {
                                                    setSelectedCompanies(e)
                                                }}
                                                selectedLabel={'businessName'}
                                                value={selectedCompanies ? selectedCompanies : []}
                                                id={`selectedCompanies`}
                                                height={8}
                                            />
                                        </LabelInput>
                                    }
                                    <div className="flex gap-3 md:flex-wrap 2xl:flex-row sm:mt-5">
                                        <Button onClick={() => {validatorFields()}}>Filtrar</Button>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center p-2">
                                    <Tippy
                                        content={<span>Aparência</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <FaEye className="text-primaryDefaultLight text-xl items-center justify-center cursor-pointer" onClick={() => setShowUniversalModal(true)}/>
                                        </div>
                                    </Tippy>
                                    
                                </div>
                            </div>
                            <div className={`hidden sm:flex sm:flex-col md:h-[44vh] lg:h-[50vh] xl:h-[52vh] 2xl:h-[58vh] relative mt-5 w-full`}>           
                                {
                                    loadingFilter ? 
                                    <div className="flex flex-row w-full items-center justify-center">
                                        <LoadingPulse/>
                                    </div>
                                    :
                                    <div className="flex flex-col pb-2 shadow-xl pr-2 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 relative rounded-md">
                                        <table className='border-collapse' id='TableToExport'>
                                            <thead className='m-0 p-0 bg-primaryDefaultLight text-white font-bold relative'>
                                            {
                                                data?.length > 0 &&
                                                Object.keys(data[0])?.map(key => {    
                                                    const verifyItensAtParams = Object.keys(filterList)?.map(keyFilter => {
                                                        const filterItemsOfParams = filterParams?.filter(itemParam => itemParam?.id == keyFilter && filterList[keyFilter] == true)[0]
                                                        return filterItemsOfParams
                                                    }).filter(item => item)               
                                                    const verifyFilter = verifyItensAtParams.filter(fil => fil?.id == key)
                                                    if (verifyFilter.length > 0){
                                                        return headerTable(verifyFilter[0]?.description, verifyFilter[0]?.colspan)                                      
                                                    } 
                                                })
                                            }
                                            </thead>
                                            <tbody className='pb-2 m-0'>
                                                {
                                                    data?.map((item, index) => {
                                                        item.unicId = uuid()
                                                        const arrayFilter = []
                                                        Object.keys(item)?.map(key => {
                                                            const verifyFilter = filterParams?.filter(fil => fil?.id == key)
                                                            if (verifyFilter.length > 0){
                                                                arrayFilter.push(verifyFilter[0])
                                                            } 
                                                        })
                                                        const verifyFilters = arrayFilter?.map(params => {
                                                            if (filterList[params?.id]){
                                                                switch (params?.id) {                                                            
                                                                    case "businessName":
                                                                        return rowTable(item?.businessName ? item?.businessName : '-', index)
                                                                    break;
                                                                    case "saleDate":
                                                                        return rowTable(item?.saleDate && selectedFilters?.length == 0 ?  moment(item?.saleDate, 'YYYY-MM').utc(false)?.format('MM/YYYY') : item?.saleDate && item?.saleDate != '-' ? moment(item?.saleDate).utc(false)?.format('DD/MM/YYYY') : '-', index)
                                                                    break;
                                                                    case "saleOrder":
                                                                        return rowTable(item?.saleOrder ? item?.saleOrder : '-', index)
                                                                    break;
                                                                    case "nf":
                                                                        return rowTable(item?.nf ? item?.nf : '-', index)
                                                                    break;
                                                                    case "client_description":
                                                                        return rowTable(item?.client_description ? item?.client_description : '-', index)
                                                                    break;
                                                                    case "externalId":
                                                                        return rowTable(item?.externalId ? item?.externalId : '-', index)
                                                                    break;
                                                                    case "addressState":
                                                                        return rowTable(item?.addressState ? item?.addressState : '-', index)
                                                                    break;
                                                                    case "code":
                                                                        return rowTable(item?.code ? item?.code : '-', index)
                                                                    break;
                                                                    case "product_externalId":
                                                                        return rowTable(item?.product_externalId ? item?.product_externalId : '-', index)
                                                                    break;
                                                                    case "typeProduct":
                                                                        return rowTable(item?.typeProduct ? item?.typeProduct : '-', index)
                                                                    break;
                                                                    case "seller_description":
                                                                        return rowTable(item?.seller_description ? item?.seller_description : '-', index)
                                                                    break;
                                                                    case "description":
                                                                        return rowTable(item?.description ? item?.description : '-', index)
                                                                    break;
                                                                    case "unitMeasure":
                                                                        return rowTable(item?.unitMeasure ? item?.unitMeasure : '-', index)
                                                                    break;
                                                                    case "productsGroup":
                                                                        return rowTable(item?.productsGroup ? item?.productsGroup : '-', index)
                                                                    break;
                                                                    case "quantity":
                                                                        return rowTable(item?.quantity ? item?.quantity : '-', index)
                                                                    break;
                                                                    case "unitPrice":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.unitPrice ? Number(item?.unitPrice)?.toFixed(5) : 0), index)
                                                                    break;
                                                                    case "discount":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.discount ? Number(item?.discount)?.toFixed(5) : 0), index)
                                                                    break;
                                                                    case "totalSale":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.totalSale)?.toFixed(5)), index)
                                                                    break;
                                                                    case "cost":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.cost ? Number(item?.cost)?.toFixed(5) : 0), index)
                                                                    break;
                                                                    case "factorMeasurable":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.factorMeasurable ? item?.factorMeasurable : 0), index)
                                                                    break;
                                                                    case "cpv":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.cpv ? item?.cpv : 0), index)
                                                                    break;
                                                                    case "cpvFat":
                                                                        return rowTable(`${Number(item?.cpvFat)?.toFixed(2)}%`, index)
                                                                    break;
                                                                    case "commission":
                                                                        return (
                                                                            <>
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueCommission)?.toFixed(5)), index, 'l')}
                                                                                {rowTable(item?.commission ? `${item?.commission}%` : '0%', index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "freight":
                                                                        return (
                                                                            <>
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.freight)?.toFixed(5)), index, 'l')}
                                                                                {rowTable(item?.freight ? `${item?.percentageFreight}%` : '0%', index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "days":
                                                                        return rowTable(`${item?.days} dias`, index)
                                                                    break;
                                                                    case "totalChange":
                                                                        return (
                                                                            <>
                                                                                {rowTable(item?.factorMeasurable ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.totalChange)?.toFixed(5)) : item?.notValue, index, 'l')}
                                                                                {rowTable(item?.percentageChange == 'NaN' ? '0.00' : item?.percentageChange ? `${item?.percentageChange}%` : '0%', index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "baseTax":
                                                                        return rowTable(item?.factorMeasurable ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.factorMeasurable)?.toFixed(5)) : item?.notValue, index, 'r')
                                                                    break;
                                                                    case "icms":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.icms == 'NaN' ? '0.00' : item?.icms ? item?.icms : 0}%`, index, 'l')}
                                                                                {rowTable(item?.icms ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueIcms)?.toFixed(5)) : item?.notValue, index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "pis":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.pis == 'NaN' ? '0.00' : item?.pis ? item?.pis : 0}%`, index, 'l')}
                                                                                {rowTable(item?.pis ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valuePis)?.toFixed(5)) : item?.notValue, index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "cofins":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.cofins == 'NaN' ? '0.00' : item?.cofins ? item?.cofins : 0}%`, index, 'l')}
                                                                                {rowTable(item?.cofins ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueCofins)?.toFixed(5)) : item?.notValue, index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "simplePercentage":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.simplePercentage == 'NaN' ? '0.00' : item?.simplePercentage ? item?.simplePercentage : 0}%`, index, 'l')}
                                                                                {rowTable(item?.simplePercentage ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueSimple)?.toFixed(5)) : item?.notValue, index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "ircs":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.ircs == 'NaN' ? '0.00' : item?.ircs ? item?.ircs : 0}%`, index, 'l')}
                                                                                {rowTable(item?.ircs ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueIrcs)?.toFixed(5)) : item?.notValue, index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "iva":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.iva == 'NaN' ? '0.00' : item?.iva ? item?.iva : 0}%`, index, 'l')}
                                                                                {rowTable(item?.iva ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueIva)?.toFixed(5)) : item?.notValue, index)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "priceLiquid":
                                                                        return rowTable(item?.factorMeasurable ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.priceLiquid)?.toFixed(5)) : item?.notValue, index)
                                                                    break;
                                                                    case "marginTotal":
                                                                        return (
                                                                            <>
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((item?.marginTotal / item?.quantity)?.toFixed(5)), index, 'l')}
                                                                                {rowTable(`${![0, '', 'NaN'].includes(item?.factorMeasurable) ? ((item?.marginTotal / item?.factorMeasurable) * 100)?.toFixed(2) : '0.00'}%`, index, 'n')}
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.marginTotal)?.toFixed(5)), index, 'r')}
                                                                            </>
                                                                        )
                                                                    break;
                                                                }                                     
                                                            }
                                                        })

                                                        return (
                                                            <tr onClick={() => handleRow(item)} className={`${item?.unicId == selectedRow?.unicId ? 'border border-gray-800' : ''} ${index % 2 == 0 ? 'bg-white dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                                                {verifyFilters}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    selectedTotal?.map((item, index) => {
                                                        item.unicId = uuid()
                                                        const arrayFilter = []
                                                        Object.keys(data[0])?.map(key => {
                                                            const verifyFilter = filterParams?.filter(fil => fil?.id == key)
                                                            if (verifyFilter.length > 0){
                                                                arrayFilter.push(verifyFilter[0])
                                                            } 
                                                        })
                                                        const verifyFilters = arrayFilter?.map((params, indexF) => {
                                                            if (indexF == 0){
                                                                return rowTable('Total', index, '', true)
                                                            }
                                                            if (filterList[params?.id]){
                                                                switch (params?.id) {                                                            
                                                                    case "businessName":
                                                                        return rowTable(item?.businessName ? item?.businessName : '-', index, '', true)
                                                                    break;
                                                                    case "saleDate":
                                                                        return rowTable(item?.saleDate && selectedFilters?.length == 0 ? item?.saleDate : item?.saleDate ? "-" : '-', index, '', true)
                                                                    break;
                                                                    case "saleOrder":
                                                                        return rowTable(item?.saleOrder ? item?.saleOrder : '-', index, '', true)
                                                                    break;
                                                                    case "nf":
                                                                        return rowTable(item?.nf ? item?.nf : '-', index, '', true)
                                                                    break;
                                                                    case "client_description":
                                                                        return rowTable(item?.client_description ? item?.client_description : '-', index, '', true)
                                                                    break;
                                                                    case "externalId":
                                                                        return rowTable(item?.externalId ? item?.externalId : '-', index, '', true)
                                                                    break;
                                                                    case "addressState":
                                                                        return rowTable(item?.addressState ? item?.addressState : '-', index, '', true)
                                                                    break;
                                                                    case "code":
                                                                        return rowTable(item?.code ? item?.code : '-', index, '', true)
                                                                    break;
                                                                    case "product_externalId":
                                                                        return rowTable(item?.product_externalId ? item?.product_externalId : '-', index, '', true)
                                                                    break;
                                                                    case "typeProduct":
                                                                        return rowTable(item?.typeProduct ? item?.typeProduct : '-', index, '', true)
                                                                    break;
                                                                    case "seller_description":
                                                                        return rowTable(item?.seller_description ? item?.seller_description : '-', index, '', true)
                                                                    break;
                                                                    case "description":
                                                                        return rowTable(item?.description ? item?.description : '-', index, '', true)
                                                                    break;
                                                                    case "unitMeasure":
                                                                        return rowTable(item?.unitMeasure ? item?.unitMeasure : '-', index, '', true)
                                                                    break;
                                                                    case "productsGroup":
                                                                        return rowTable(item?.productsGroup ? item?.productsGroup : '-', index, '', true)
                                                                    break;
                                                                    case "quantity":
                                                                        return rowTable(item?.quantity ? item?.quantity : '-', index, '', true)
                                                                    break;
                                                                    case "unitPrice":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.unitPrice ? Number(item?.unitPrice)?.toFixed(5) : 0), index, '', true)
                                                                    break;
                                                                    case "discount":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.discount ? Number(item?.discount)?.toFixed(5) : 0), index, '', true)
                                                                    break;
                                                                    case "totalSale":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.totalSale)?.toFixed(5)), index, '', true)
                                                                    break;
                                                                    case "cost":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.cost ? Number(item?.cost)?.toFixed(5) : 0), index, '', true)
                                                                    break;
                                                                    case "factorMeasurable":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item?.factorMeasurable ? item?.factorMeasurable : 0), index, '', true)
                                                                    break;
                                                                    case "cpv":
                                                                        return rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.cpv)?.toFixed(5)), index, '', true)
                                                                    break;
                                                                    case "cpvFat":
                                                                        return rowTable(`${Number(item?.cpvFat)?.toFixed(2)}%`, index, '', true)
                                                                    break;
                                                                    case "commission":
                                                                        return (
                                                                            <>
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueCommission)?.toFixed(5)), index, 'l', true)}
                                                                                {rowTable(item?.commission ? `${item?.commission}%` : '0%', index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "freight":
                                                                        return (
                                                                            <>
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.freight)?.toFixed(5)), index, 'l', true)}
                                                                                {rowTable(item?.freight ? `${item?.percentageFreight}%` : '0%', index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "days":
                                                                        return rowTable(`${item?.days} dias`, index, '', true)
                                                                    break;
                                                                    case "totalChange":
                                                                        return (
                                                                            <>
                                                                                {rowTable(item?.factorMeasurable ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.totalChange)?.toFixed(5)) : item?.notValue, index, 'l', true)}
                                                                                {rowTable(item?.percentageChange == 'NaN' ? '0.00' : item?.percentageChange ? `${item?.percentageChange}%` : '0%', index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "baseTax":
                                                                        return rowTable(item?.factorMeasurable ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.factorMeasurable)?.toFixed(5)) : item?.notValue, index, 'r', true)
                                                                    break;
                                                                    case "icms":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.icms == 'NaN' ? '0.00' : item?.icms ? item?.icms : 0}%`, index, 'l', true)}
                                                                                {rowTable(item?.icms ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueIcms)?.toFixed(5)) : item?.notValue, index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "pis":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.pis == 'NaN' ? '0.00' : item?.pis ? item?.pis : 0}%`, index, 'l', true)}
                                                                                {rowTable(item?.pis ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valuePis)?.toFixed(5)) : item?.notValue, index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "cofins":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.cofins == 'NaN' ? '0.00' : item?.cofins ? item?.cofins : 0}%`, index, 'l', true)}
                                                                                {rowTable(item?.cofins ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueCofins)?.toFixed(5)) : item?.notValue, index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "simplePercentage":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.simplePercentage == 'NaN' ? '0.00' : item?.simplePercentage ? item?.simplePercentage : 0}%`, index, 'l', true)}
                                                                                {rowTable(item?.simplePercentage ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueSimple)?.toFixed(5)) : item?.notValue, index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "ircs":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.ircs == 'NaN' ? '0.00' : item?.ircs ? item?.ircs : 0}%`, index, 'l', true)}
                                                                                {rowTable(item?.ircs ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueIrcs)?.toFixed(5)) : item?.notValue, index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "iva":
                                                                        return (
                                                                            <>
                                                                                {rowTable(`${item?.iva == 'NaN' ? '0.00' : item?.iva ? item?.iva : 0}%`, index, 'l', true)}
                                                                                {rowTable(item?.iva ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.valueIva)?.toFixed(5)) : item?.notValue, index, '', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                    case "priceLiquid":
                                                                        return rowTable(item?.factorMeasurable ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.priceLiquid)?.toFixed(5)) : item?.notValue, index, '', true)
                                                                    break;
                                                                    case "marginTotal":
                                                                        return (
                                                                            <>
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((item?.marginTotal / item?.quantity)?.toFixed(5)), index, 'l', true)}
                                                                                {rowTable(`${![0, '', 'NaN'].includes(item?.factorMeasurable) ? ((item?.marginTotal / item?.factorMeasurable) * 100)?.toFixed(2) : '0.00'}%`, index, 'n', true)}
                                                                                {rowTable(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(item?.marginTotal)?.toFixed(5)), index, 'r', true)}
                                                                            </>
                                                                        )
                                                                    break;
                                                                }                                     
                                                            }
                                                        })

                                                        return (
                                                            <tr onClick={() => handleRow(item)} className={`${item?.unicId == selectedRow?.unicId ? 'border border-gray-600' : ''} bg-primaryDefaultLight`}>
                                                                {verifyFilters}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }                       
                            </div>
                            <div className="hidden sm:flex w-full flex-wrap gap-1">
                                {
                                    filterParams?.map(params => {
                                        if (!params?.editable){
                                            const verifyFilters = selectedFilters?.find(item => item == params?.description)  
                                            return (
                                                <div onClick={() => !verifyFilters ? addFilter(params) : {}} className={`${verifyFilters ? 'bg-white border border-primaryDefaultLight text-primaryDefaultLight' : 'bg-primaryDefaultLight border border-primaryDefaultLight text-white hover:bg-secondaryDefaultLight'} cursor-pointer px-1 flex flex-row gap-1 rounded-md select-none`}>
                                                    <p className="text-sm">{params?.description}</p>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                <div className={`hidden absolute right-2 bottom-2 ${selectedFilters?.length == 0 ? 'sm:hidden' : 'sm:flex'}`}>
                                    <Button onClick={() => removeLastFilter()} approval={false} height={6}>Voltar</Button>
                                </div>
                            </div>
                            <div className="flex sm:hidden text-center">
                                <p>Essa funcionalidade está disponível apenas para computador</p>
                            </div>
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}