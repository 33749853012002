//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//services
import { api } from "../../../services/api/api";
import { v4 as uuid } from 'uuid'
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { typeProducts } from '../../../utils/typeProduct/typeProduct';
import { justNumber } from '../../../utils/validators/justNumber';
import { validatorDatasheet } from '../../../utils/validators/validatorDatasheet';
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { LabelInput } from "../../../components/label/label.input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Input } from '../../../components/input/input';
import { Button } from '../../../components/buttons/button.default';
import moment from 'moment';
import { useScreenSizeContext } from '../../../contexts/screenSizeContext';

export function DataSheetCreate() {

    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const typeProductsList = typeProducts()

    const [productsData, setProductsData] = useState([])
    const [childsDatasheetList, setChildDatasheetList] = useState([])
    const [childDatasheetIdList, setChildDatasheetIdList] = useState([])
    const [productsMasterCode, setProductsMasterCode] = useState('')
    const [productMaster, setMasterProduct] = useState('')
    const [observation, setObservation] = useState('')
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))

    useEffect(() => {
        async function getProductsDataApi() {
            setLoading(true)
            try {
                const productsDataApi = await api.get('/api/v1/lm/products?active=true', {
                    headers: {
                        authorization: `Bearer ${userData[0].token}`
                    }
                })
                setProductsData(productsDataApi?.data?.data)
                setLoading(false)
            } catch(error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getProductsDataApi()
        addChildDatasheet()
    }, [])

    function addChildDatasheet() {

        const id = uuid()
        let add

        add = Object.assign(childsDatasheetList, {
            [id]: {
                products_id: null,
                linked_datasheet_id: null,
                quantity: null
            }
        })

        setChildDatasheetList(add)
        setChildDatasheetIdList([...childDatasheetIdList, id])
    }

    async function removeChildDatasheet(id) {
            setLoading(true)
            await new Promise(resolve => setTimeout(resolve, 50))

            const replicaChildDatasheet = structuredClone(childsDatasheetList)
            const replicaChildDatasheetList = structuredClone(childDatasheetIdList)

            const position = childDatasheetIdList.indexOf(id)
            replicaChildDatasheetList.splice(position, 1)

            delete replicaChildDatasheet[id]

            setChildDatasheetList(replicaChildDatasheet)
            setChildDatasheetIdList([...replicaChildDatasheetList])
            setLoading(false)
    }

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'productsMasterCode', value: productsMasterCode, required: true, type: 'string' },
            { name: 'productMaster', value: productMaster, required: true, type: 'string' },
            { name: 'startDate', value: startDate, required: true, type: 'string'}
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (childDatasheetIdList == 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('É necessário compor a ficha técnica')
        }

        if (Object.keys(childsDatasheetList).length > 0) {
            if (validatorDatasheet(childsDatasheetList).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha os campos em vermelho')

            }
        }

        try {
            const formatListChilds = childDatasheetIdList?.map(key => {
                return {
                    products_id: childsDatasheetList[key]?.products_id ? childsDatasheetList[key]?.products_id : undefined,
                    quantity: Number(childsDatasheetList[key]?.quantity)?.toFixed(5)
                }
            })

            await api.post(`/api/v1/lm/datasheet`, {
                products_id: productMaster,
                observation: observation ? observation : undefined,
                startDate: startDate,
                composition: formatListChilds
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Ficha técnica cadastrada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/datasheet')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={'Cadastro de Ficha Técnica'} />
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <DefaultLoader />
                        :
                        <>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='w-full flex flex-row flex-wrap justify-start items-start gap-2'>
                                    <LabelInput 
                                        text={'Tipo de produto'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir qual o tipo de produto para a ficha técnica  (Apenas produtos PA e PI podem ter ficha).'}
                                    >
                                        <InputAutoComplete
                                            data={typeProductsList?.filter(type => [5, 4].includes(type.codeProduct))}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                            type={'string'}
                                            width={52}
                                            value={typeProductsList.filter(type => type.codeProduct == productsMasterCode)[0]?.name}
                                            onChange={(e) => {
                                                setProductsMasterCode(e.codeProduct)
                                                setMasterProduct('')
                                            }}
                                            id='productsMasterCode'
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Produto'} required={true}>
                                        <InputAutoComplete
                                            data={productsData?.filter(product => product.typeProduct == typeProductsList.filter(type => productsMasterCode == type.codeProduct)[0]?.typeProduct && !product.datasheet_id)}
                                            selectedLabel={'description'}
                                            optionList={['code', 'description']}
                                            type={'string'}
                                            widthForce={screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : 'w-[30rem]'}
                                            value={productMaster ? productsData.filter(prod => prod.id == productMaster)[0]?.description : ''}
                                            preSelectedValue={productMaster ? productsData.filter(prod => prod.id == productMaster)[0]?.description : ''}
                                            onChange={(e) => setMasterProduct(e.id)}
                                            disabled={typeProductsList.filter(type => type.codeProduct == productsMasterCode)[0]?.name ? false : true}
                                            id='productMaster'
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Observação'} required={false}>
                                        <Input
                                            type={'string'}
                                            width={80}
                                            value={observation}
                                            id='observation'
                                            onChange={(e) => setObservation(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data criação'} required={false}>
                                        <Input
                                            type={'date'}
                                            width={'120px'}
                                            value={startDate}
                                            id='startDate'
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </LabelInput>
                                </form>
                                <div className="text-sm 2xl:text-base mt-5">
                                    Composições
                                </div>
                                <div>
                                    <table className={`w-full mt-3 flex flex-col justify-start items-start rounded-md border border-gray-400 bg-gray-50`}>
                                        <thead className='flex flex-row gap-4 sticky -top-2 z-[21] bg-blue-50 items-start justify-start w-full rounded-md'>
                                            <tr className='p-1 text-gray-700'>
                                                <th className='w-52 font-medium'>Tipo de produto</th>
                                                <th className={`font-medium ${screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : screenX == 1024 ? 'w-[23rem]' : 'w-[40rem]'}`}>Produto</th>
                                                <th className='w-20 font-medium translate-x-3'>Quantidade</th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-1 pb-4'>
                                        {
                                            childDatasheetIdList.map((child, index) => {
                                                return (
                                                    <tr className={`lg:flex lg:flex-row gap-2 justify-start items-center`}>
                                                        <LabelInput>
                                                            <InputAutoComplete
                                                                data={productsMasterCode == 4 ? typeProductsList?.filter(type => type?.typeProduct != "PA") : productsMasterCode == 5 ? typeProductsList?.filter(type => type?.typeProduct != "PA") : typeProductsList}
                                                                selectedLabel={'name'}
                                                                optionList={['name']}
                                                                type={'string'}
                                                                width={52}
                                                                value={typeProductsList.filter(type => type.codeProduct == childsDatasheetList[child]?.typeProducts)[0]?.name}
                                                                onChange={(e) => {
                                                                    childsDatasheetList[child].typeProducts = e?.codeProduct
                                                                    childsDatasheetList[child].products_id = ''
                                                                    setChildDatasheetList(childsDatasheetList)
                                                                    setChildDatasheetIdList([...childDatasheetIdList])
                                                                }}
                                                                id={`${child} - typeProduct`}
                                                                disabled={!productsMasterCode ? true : false}
                                                            />
                                                        </LabelInput>
                                                        <LabelInput>
                                                            <InputAutoComplete
                                                                data={productsData?.filter(type => type.typeProduct == typeProductsList.filter(type => childsDatasheetList[child]?.typeProducts == type.codeProduct)[0]?.typeProduct && type?.id != productMaster)}
                                                                selectedLabel={'description'}
                                                                optionList={['code', 'description']}
                                                                type={'string'}
                                                                widthForce={screenX > 1536 ? 'w-[45rem]' : screenX > 1024 && screenX < 1280 ? 'w-[20rem]' : screenX < 1024 ? false : screenX == 1024 ? 'w-[23rem]' : 'w-[40rem]'}
                                                                value={childsDatasheetList[child].products_id ? productsData.filter(prod => prod.id == childsDatasheetList[child].products_id)[0]?.description : ''}
                                                                preSelectedValue={childsDatasheetList[child].products_id ? productsData.filter(prod => prod.id == childsDatasheetList[child].products_id && prod.code == childsDatasheetList[child].typeProducts)[0]?.description : ''}
                                                                onChange={(e) => {
                                                                    childsDatasheetList[child].products_id = e?.id
                                                                    setChildDatasheetList(childsDatasheetList)
                                                                    setChildDatasheetIdList([...childDatasheetIdList])
                                                                }}
                                                                disabled={typeProductsList.filter(type => type.codeProduct == childsDatasheetList[child]?.typeProducts)[0]?.name ? false : true}
                                                                id={`${child} - products_id`}
                                                            />
                                                        </LabelInput>
                                                        <LabelInput>
                                                            <Input
                                                                type={'number'}
                                                                value={childsDatasheetList[child].quantity ? childsDatasheetList[child].quantity : ''}
                                                                width={20}
                                                                disabled={!productsMasterCode ? true : false}
                                                                onChange={(e) => {
                                                                    childsDatasheetList[child].quantity = e.target.value
                                                                    setChildDatasheetList(childsDatasheetList)
                                                                    setChildDatasheetIdList([...childDatasheetIdList])
                                                                }}
                                                                id={`${child} - quantity`}
                                                            />
                                                        </LabelInput>
                                                        <div className='flex flex-row gap-3 justify-center items-center ml-3 hover:cursor-pointer text-md text-blue-600 mr-2'>
                                                            {childDatasheetIdList.length > 1 && <FaMinus className='text-sm' onClick={() => { removeChildDatasheet(child) }} />}
                                                            <FaPlus className='text-sm' onClick={() => addChildDatasheet()} />
                                                        </div>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-8 md:mt-2 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                <Button
                                    onClick={() => navigate('/datasheet')}
                                    shadow={true}
                                    approval={false}
                                >Cancelar
                                </Button>
                                <Button
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Cadastrar
                                </Button>
                            </div>
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}