import { useEffect, useState } from "react";
//components
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { validateFields } from "../../../utils/form.validator";
import { api } from "../../../services/api/api";
import { useAuth } from "../../../contexts/useAuth";
import { responseError } from "../../../utils/responsesFunctions/error.response";
//context
import { useThemeContext } from "../../../contexts/themeContext";
import { useNavigate } from 'react-router-dom'

import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";

import moment from "moment";

export function DeterminationPeriodCreate() {
    const navigate = useNavigate()

    const { userData } = useAuth()
    const [month, setMonth] = useState('')
    const [year, setYear] = useState(moment().year())
    const [preYear, setPreYear] = useState(moment().year())
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'month', value: month, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/general/periods', {
                month,
                year: year ? Number(year) : Number(preYear)

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/determinationPeriod')

        } catch (error) {

            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    const monthData = [
        { id: 1, name: 'Janeiro' },
        { id: 2, name: 'Fevereiro' },
        { id: 3, name: 'Março' },
        { id: 4, name: 'Abril' },
        { id: 5, name: 'Maio' },
        { id: 6, name: 'Junho' },
        { id: 7, name: 'Julho' },
        { id: 8, name: 'Agosto' },
        { id: 9, name: 'Setembro' },
        { id: 10, name: 'Outubro' },
        { id: 11, name: 'Novembro' },
        { id: 12, name: 'Dezembro' },
    ]

    const yearsData = Array.from({ length: 100 }, (value, index) => {
        return {
            id: index,
            value: 2000 + (index + 1)
        }
    })

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Periodos de apuração'} />
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader />
                    :
                    <div className='flex flex-col gap-4 items-center lg:items-start justify-center lg:justify-start w-full'>
                        <form className="flex flex-col md:flex-row gap-4">
                            <LabelInput text={'Mês *'}>
                                <InputAutoComplete
                                    data={monthData}
                                    type={'text'}
                                    id='month'
                                    selectedLabel={['name']}
                                    optionList={['name']}
                                    width={80}
                                    preSelectedValue={monthData.filter(item => item?.id == month)[0]?.name}
                                    value={monthData.filter(item => item?.id == month)[0]?.name}
                                    onChange={(e) => setMonth(e?.id)}

                                />
                            </LabelInput>
                            <LabelInput text={'Ano *'}>
                                <InputAutoComplete
                                    data={yearsData}
                                    type={'text'}
                                    id='year'
                                    optionList={['value']}
                                    selectedLabel={['value']}
                                    width={80}
                                    preSelectedValue={preYear ? preYear : ''}
                                    value={year ? year : ''}
                                    onChange={(e) => {
                                        setYear(e?.value)
                                    }}
                                />
                            </LabelInput>

                        </form>
                        <div className="flex gap-3 mt-3">
                            <Button approval={false} onClick={() => navigate('/determinationPeriod')}>Cancelar</Button>
                            <Button onClick={() => handleSubmit()}>Criar</Button>
                        </div>
                    </div>
                }        
            </Body>
        </Container>
    )
} 