//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//services
import { api } from "../../../services/api/api";
//utils
import { validateFields } from '../../../utils/form.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
//components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Body } from "../../../components/container/Body";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Button } from "../../../components/buttons/button.default";

export function ComissionSaleCreate() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [sellerData, setSellerData] = useState([])
    const [seller, setSeller] = useState('')
    const [percentage, setPercentage] = useState('')
    const [startDate, setStartDate] = useState('')

    useEffect(() => {
        setLoading(true)

        async function getSellerAPI() {
            const sellerDataApi = await api.get(`/api/v1/lm/seller`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setSellerData(sellerDataApi?.data?.data)
        }

        getSellerAPI()
        setLoading(false)
    }, [])

    async function handleSubmit() {
        setLoading(true)

        const requiredFields = [
            { name: 'seller', value: seller, required: true, type: 'string' },
            { name: 'percentage', value: percentage, required: true, type: 'string' },
            { name: 'startDate', value: startDate, required: true, type: 'string' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post(`/api/v1/lm/commissionSale`, {
                seller_id: seller,
                percentage: percentage,
                startDate: startDate
            }, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/comissionSale')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={'Cadastro de Comissão'} />
                    </div>
                </TitlePage>
                {
                    loading ?
                        <DefaultLoader />
                        :
                        <Body>
                            <div className='flex flex-col items-center lg:items-start justify-center lg:justify-start w-full'>
                                <form className='flex flex-col items-center lg:grid md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                    <LabelInput 
                                        text={'Vendedor'} 
                                        required={true}
                                        showIconInfo={true}
                                        messageIconInfo={'Definir o vendedor para aplicar a porcentagem da comissão.'}
                                    >
                                        <InputAutoComplete
                                            data={sellerData}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                            type={'string'}
                                            width={80}
                                            value={sellerData.filter(item => item.id == seller)[0]?.name}
                                            id='seller'
                                            onChange={(e) => setSeller(e.id)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Porcentagem'} required={true}>
                                        <Input
                                            type={'number'}
                                            width={80}
                                            value={percentage}
                                            id='percentage'
                                            onChange={(e) => setPercentage(e.target.value)}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Data Inicial'} required={true}>
                                        <Input
                                            type={'date'}
                                            width={'150px'}
                                            value={startDate}
                                            id='startDate'
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </LabelInput>
                                </form>
                                <div className='mt-8 mb-5 md:mt-5 flex flex-row gap-5 lg:gap-1 w-full items-center pb-5 lg:pb-0 justify-center lg:justify-start'>
                                    <Button
                                        onClick={() => navigate('/comissionSale')}
                                        shadow={true}
                                        approval={false}
                                    >Cancelar
                                    </Button>
                                    <Button
                                        shadow={true}
                                        onClick={() => handleSubmit()}
                                    >Cadastrar
                                    </Button>
                                </div>
                            </div>
                        </Body>
                }
            </Container>
        </>
    )
}